import React, { useState, useEffect } from "react";
import { BiExport, BiImport } from "react-icons/bi";
import ButtonPagenate from "../ButtonPagenate/ButtonPagenate";
import { useSelector } from "react-redux";
import FormatDateAndTime from "../formatDateTime";

const PaymentRecord = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const { searchdata } = useSelector((state) => state.searchfilter);
  const { levyhistory } = useSelector((state) => state.datareponse);
  console.log(levyhistory);

  useEffect(() => {
    setPaymentHistory(levyhistory);
  }, [levyhistory]);

  // Function to handle checkbox change
  const handleCheckboxChange = (id) => {
    console.log(id);
    setPaymentHistory((prevData) =>
      prevData.map((item) =>
        item.ID === id ? { ...item, ischecked: !item.ischecked } : item
      )
    );
  };

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const itemsPerpage = 20; //Number of items to be displayed per page
  const pageCount = Math.ceil(paymentHistory?.length / itemsPerpage);
  const pagesVisited = pageNumber * itemsPerpage;

  var newdataset = paymentHistory?.filter((filteredItem) => {
    if (searchdata === "") {
      return filteredItem;
    } else if (
      filteredItem.levy_label.toLowerCase().includes(searchdata) ||
      filteredItem.targetdemograph.toLowerCase().includes(searchdata)
    ) {
      return filteredItem;
    }
  });

  const handleMakePayment = () => {
    const selectedRows = paymentHistory.filter((item) => item.ischecked);
    // Perform your action (e.g., make payment) on selectedRows
    console.log("Making payment for:", selectedRows);
  };

  return (
    <div className="w-full h-full flex flex-col items-center bg-[#f9f9f9] gap-1">
      <div className="bg-[white] shadow p-1 w-full flex items-center justify-end gap-1">
        <div className="text-[white] bg-[#8c40c7] text-[12px] flex items-center cursor-pointer p-2 px-4 text-center rounded-[3px] hover:shadow">
          <BiImport size={18} className="mr-2" color="white" />
          Import
        </div>

        <div className="text-[white] bg-[#48c75b] text-[12px] flex items-center cursor-pointer p-2 px-4 text-center rounded-[3px] hover:shadow">
          <BiExport size={18} className="mr-2" color="white" />
          Export
        </div>
      </div>

      <div className="w-full bg-[white] min-h-[70vh] flex flex-col gap-5 max-w-screen h-full overflow-x-auto relative">
        <table className="bnw-claims-table">
          <thead id="bnw-claims-table-head">
            <tr className="text-[13px] uppercase w-[80px]">
              <th scope="col" className="w-[30px]">
                <input type="checkbox" onChange={() => {}} />
              </th>
              <th scope="col">TID</th>
              <th scope="col">LEVY NAME</th>
              <th scope="col">AMOUNT</th>
              <th scope="col">DATE</th>
              <th scope="col">STATUS</th>
            </tr>
          </thead>

          <tbody>
            {newdataset
              ?.slice(pagesVisited, pagesVisited + itemsPerpage)
              .map((item, index) => (
                <tr
                  key={index}
                  className="cursor-pointer text-[12px] font-normal"
                >
                  <td data-label="#SN" className="bg-[#f9f9f9]">
                    <input
                      type="checkbox"
                      checked={item.ischecked}
                      onChange={() => handleCheckboxChange(item.ID)}
                      className="cursor-pointer"
                    />
                  </td>

                  <td data-label="TID">{item.transaction_id}</td>

                  <td data-label="LEVY NAME">{item.levy_label}</td>

                  <td data-label="AMOUNT">{item.amount}</td>

                  <td data-label="DATE">{FormatDateAndTime(item.date).date}</td>

                  <td data-label="STATUS">
                    <div
                      className={`flex text-center items-center justify-start w-full p-2 pl-5`}
                    >
                      <div
                        className={`w-[8px] h-[8px] rounded-full ${
                          item.status === 1
                            ? "bg-[green]"
                            : item.status === 2
                            ? "bg-[orange]"
                            : "bg-[red]"
                        } mr-3`}
                      />

                      {item.status === 1
                        ? "Completed"
                        : item.status === 2
                        ? "Pending"
                        : "Incomplete"}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <div className="w-fit flex">
          <ButtonPagenate
            updatedPageNum={newPagenum}
            pageCount={pageCount}
            pageNumber={pageNumber}
            previousLabel="Prev"
            nextLabel="Next"
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentRecord;
