export const currentPage = (currentpage) => ({
    type: "CurrentTab",
    payload: currentpage,
});

export const authenticationStatus = (responseData) => ({
    type: "LoginResponse",
    payload: responseData,
});

export const searchFilter = (searchData) => ({
    type: "SearchResponse",
    payload: searchData,
});

export const clickedTab = (tab) => ({
    type: "SideTab",
    payload: tab,
});

