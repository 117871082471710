import React, { useState, useEffect } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { Store, resetStore } from "../../Redux/store";
import { authenticationStatus, currentPage } from "../../Redux/Actions/pages";
import ReactLoading from "react-loading";

const Login = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // 👇️ scroll to top on page load
    Store.dispatch(currentPage("login"));
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    Store.dispatch(
      authenticationStatus({
        isAuthenticated: false,
        userdata: {},
      })
    );
  }, []);

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [cookies, setCookie] = useCookies(["Token"]);
  const [tab, setTab] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    await axios
      .post(`${server}/auth/login-user`, {
        email,
        password,
      })
      .then((res) => {
        toast.success("Login Success!");
        setLoading(false);
        setCookie("Token", res?.data?.token, { path: "/" }); // Set Token To the Cookie Value and make it accessible to all routes
        resetStore();
        console.log(res?.data?.token);

        Store.dispatch(
          authenticationStatus({
            isAuthenticated: true,
            userdata: res?.data?.user,
          })
        );
        navigate("/dashboard");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
        toast.error(err?.response?.data);
      });
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    await axios
      .post(
        `${server}/auth/verify-email`,
        {
          email,
          password,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Login Success!");
        navigate("/");
        window.location.reload(true);
      })
      .catch((err) => {
        toast.error("error: ", err.response);
        // console.log(err.response);
      });
  };

  const handleVerifyEmail = async (e) => {
    e.preventDefault();

    await axios
      .post(`${server}/auth/verifyemail`, {
        email,
      })
      .then((res) => {
        // console.log(res);
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        {tab === 0 ? (
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Login to your account
          </h2>
        ) : (
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Reset Password
          </h2>
        )}
      </div>
      <div className="mt-8 w-[40%] min-w-[400px] sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-10 px-6 rounded-lg shadow sm:rounded-lg sm:px-10">
          {tab === 0 ? (
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1 relative">
                  <input
                    type={visible ? "text" : "password"}
                    name="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  {visible ? (
                    <AiOutlineEye
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(false)}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(true)}
                    />
                  )}
                </div>
              </div>

              <div className="w-full flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="remember-me"
                    id="remember-me"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label htmlFor="remember-me" className="text-[13px]">
                    Remember me
                  </label>
                </div>

                <div className="text-[13px]">
                  <button
                    onClick={() => setTab(1)}
                    className="font-medium text-blue-600 hover:text-blue-500"
                  >
                    Forgot your password?
                  </button>
                </div>
              </div>

              <div className="relative w-full h-[50px] flex items-center">
                <input
                  disabled={loading ? true : false}
                  type="submit"
                  value="Sign In"
                  className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 cursor-pointer"
                />
                {loading && (
                  <ReactLoading
                    className="absolute left-[65%] top-[25%]"
                    type="spin"
                    color="white"
                    height={"6%"}
                    width={"6%"}
                  />
                )}
              </div>

              <div className="w-full items-center justify-between text-[15px] flex">
                {/* <h4>Not have any account?</h4> */}
                <Link to="/sign-up" className="text-blue-600 pl-2">
                  {/* Sign Up */}
                </Link>
              </div>
            </form>
          ) : (
            <form className="space-y-6" onSubmit={handleVerifyEmail}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <input
                  type="submit"
                  className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                  value="Reset Password"
                />
              </div>

              <div className="w-full items-center justify-between text-[15px] flex">
                <h4></h4>
                <div
                  className="cursor-pointer text-blue-600 pl-2 hover:underline"
                  onClick={() => setTab(0)}
                >
                  Back to Login
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
