import React, { useState } from "react";
import "../assets/css/style.css";
import "../Pages/styles.css";
import ButtonPagenate from "./ButtonPagenate/ButtonPagenate";

const PortfolioJson = [
  {
    id: 1,
    foliolink: "park6",
    type: "events",
  },
  {
    id: 2,
    foliolink: "park7",
    type: "attractions",
  },
  {
    id: 3,
    foliolink: "park8",
    type: "attractions",
  },
  {
    id: 4,
    foliolink: "portfolio-4",
    type: "events",
  },
  {
    id: 5,
    foliolink: "park4",
    type: "street",
  },
  {
    id: 6,
    foliolink: "park1",
    type: "street",
  },
  {
    id: 7,
    foliolink: "park2",
    type: "street",
  },
  {
    id: 8,
    foliolink: "park3",
    type: "street",
  },
  {
    id: 9,
    foliolink: "park4",
    type: "street",
  },
  {
    id: 10,
    foliolink: "park5",
    type: "events",
  },
  {
    id: 11,
    foliolink: "park6",
    type: "events",
  },
  {
    id: 12,
    foliolink: "park7",
    type: "events",
  },
  {
    id: 13,
    foliolink: "park8",
    type: "events",
  },
];

const Portfolio = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerpage = 8;
  const pageCount = Math.ceil(PortfolioJson.length / itemsPerpage);
  const pagesVisited = pageNumber * itemsPerpage;
  const [tab, setTab] = useState("*");

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  var newdataset = PortfolioJson.filter((filteredItem) => {
    filteredItem.type.includes(tab);
    if (filteredItem.type.includes(tab)) {
      return filteredItem;
    } else if (tab === "*") {
      return filteredItem;
    }
  });

  return (
    <div className="w-full" data-aos="fade-up">
      <div className="section-title">
        <h2>Gallery</h2>
        <p>Check our Gallery</p>
      </div>

      <div className="row" data-aos="fade-up" data-aos-delay="100">
        <div className="portfolio flex justify-center items-center">
          <ul id="portfolio-flters">
            <li
              onClick={() => setTab("*")}
              className={tab === "*" ? "filter-active" : ""}
            >
              All
            </li>
            <li
              onClick={() => setTab("events")}
              className={tab === "events" ? "filter-active" : ""}
            >
              Events
            </li>
            <li
              onClick={() => setTab("attractions")}
              className={tab === "attractions" ? "filter-active" : ""}
            >
              Attractions
            </li>
            <li
              onClick={() => setTab("street")}
              className={tab === "street" ? "filter-active" : ""}
            >
              Street View
            </li>
          </ul>
        </div>
      </div>

      <div className="flex flex-col w-full gap-3 items-center justify-center w-full">
        <div
          className="portfolio-container flex flex-wrap gap-3 justify-center py-3"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          {newdataset
            .slice(pagesVisited, pagesVisited + itemsPerpage)
            .map((items, index) => (
              <div className="w-[30%] h-[200px] max-w-[250px] grow 600px:w-[150px] 600px:h-[150px]">
                <img
                  src={require("../assets/portfolio/" +
                    items.foliolink +
                    ".jpg")}
                  className="cursor-pointer img-fluid w-full h-[200px] bg-no-repeat bg-center bg-contain 600px:h-full"
                  alt=""
                />
              </div>
            ))}
        </div>

        <div className="flex gap-1 items-center justify-center w-full">
          <ButtonPagenate
            updatedPageNum={newPagenum}
            pageCount={pageCount}
            pageNumber={pageNumber}
            previousLabel="Prev"
            nextLabel="Next"
          />
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
