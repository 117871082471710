import React, { useState } from "react";
import "../Pages/styles.css";
import axios from "axios";
import { toast } from "react-toastify";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineEmail } from "react-icons/md";
import { IoIosPhonePortrait } from "react-icons/io";

const Contact = () => {
  const API = "https://lightmax-website-backend.vercel.app";
  const [fieldvalues, setFieldvalues] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    const objcts = {
      name: fieldvalues.name,
      email: fieldvalues.email,
      subject: fieldvalues.subject,
      message: fieldvalues.message,
    };

    try {
      var config = {
        method: "POST",
        url: `${API}/contact/sendmail`,
        data: objcts,
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          toast.success(response.data.message);
          clearfields(); // Trigger DB fetch
        }
      });
    } catch (e) {
      toast.error(e.message);
      clearfields();
    }
  };

  const clearfields = () => {
    setFieldvalues({
      ...fieldvalues,
      name: "",
      email: "",
      subject: "",
      message: "",
    });
    return;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldvalues({ ...fieldvalues, [name]: value });
  };
  return (
    <div className="w-full" data-aos="fade-up">
      <div className="section-title">
        <h2>Contact</h2>
        <p>Contact Us</p>
      </div>

      <div className="flex gap-4 w-full">
        <div className="flex flex-col gap-9 w-[40%]">
          <div className="w-full flex gap-2 items-center gap-3">
            <div className="bg-[yellow] rounded-md p-3 h-[50px]">
              <CiLocationOn size={30} />
            </div>
            <div className="flex flex-col gap12">
              <h4 className="text-[18px] font-[600]">Location:</h4>
              <p>Suite 19 Primatek Plaza Egbeda Lagos</p>
            </div>
          </div>

          <div className="w-full flex gap-2 break-all">
            <div className="bg-[yellow] rounded-md p-3 h-[50px]">
              <MdOutlineEmail size={30} />
            </div>

            <div className="flex flex-col gap12">
              <h4 className="text-[18px] font-[600]">Email:</h4>
              <p>cdaomotoyeestate@gmail.com</p>
            </div>
          </div>

          <div className="w-full flex gap-2">
            <div className="bg-[yellow] rounded-md p-3 h-[50px]">
            <IoIosPhonePortrait size={30} />
            </div>

            <div className="flex flex-col gap12">
              <h4 className="text-[18px] font-[600]">Call:</h4>
              <p>+234 803 0815 975</p>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full">
          <form
            onSubmit={handleContactSubmit}
            className="w-full flex flex-col gap-3"
          >
            <div className="flex h-auto w-full gap-3 flex-wrap">
              <div className="w-auto grow min-w-[250px]">
                <input
                  type="text"
                  value={fieldvalues.name}
                  name="name"
                  onChange={handleChange}
                  className="w-full p-3 outline-none rounded-md border"
                  id="name"
                  placeholder="Your Name"
                  required
                />
              </div>

              <div className="w-auto grow min-w-[250px]">
                <input
                  type="email"
                  value={fieldvalues.email}
                  className="w-full p-3 outline-none rounded-md border"
                  name="email"
                  onChange={handleChange}
                  id="email"
                  placeholder="Your Email"
                  required
                />
              </div>
            </div>

            <div className="flex h-auto w-full">
              <input
                type="text"
                value={fieldvalues.subject}
                className="w-full p-3 outline-none rounded-md border"
                name="subject"
                onChange={handleChange}
                id="subject"
                placeholder="Subject"
                required
              />
            </div>

            <div className="flex h-auto w-full">
              <textarea
                className="w-full p-3 outline-none rounded-md border"
                value={fieldvalues.message}
                name="message"
                rows="5"
                onChange={handleChange}
                placeholder="Message"
                required
              ></textarea>
            </div>

            <div className="text-center">
              <input
                className="text-[14px] rounded-md font-[600] p-2 px-4 bg-[#f5b94288] hover:bg-[#f5b942] hover:text-[white]"
                type="submit"
                value="Send Message"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
