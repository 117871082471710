const apratmentJSON = [
    {
        ID: 1,
        name: 'Mini Flat'
    },
    {
        ID: 2,
        name: '2 BEDROOM - Ensuite'
    },
    {
        ID: 3,
        name: '2 BEDROOM Flat'
    },
    {
        ID: 4,
        name: '3 BEDROOM - Ensuite'
    },
    {
        ID: 5,
        name: '3 BEDROOM Flat'
    },
    {
        ID: 6,
        name: 'Duplex'
    },
    {
        ID: 7,
        name: 'Bungalow'
    },
    {
        ID: 8,
        name: 'Self-Contain'
    }
]

export default apratmentJSON