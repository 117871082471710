import React, { useState, useEffect, useRef } from "react";
import close_icon from "../assets/close_icon.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { MdLogout } from "react-icons/md";
import { Store, persistor, resetStore } from "../Redux/store";
import { authenticationStatus } from "../Redux/Actions/pages";

function useOutsideAlerter(ref, setOpenNav) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenNav(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setOpenNav]);
}

const Navbar = ({ logo }) => {
  const [openNav, setOpenNav] = useState(false);
  const { currentab } = useSelector((state) => state.users);
  const [hover, setHover] = useState(false);

  // console.log(currentab);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setOpenNav);

  const handleHover = () => {
    setHover(true);
  };

  const handleUnhover = () => {
    setHover(false);
  };

  const ResetStore = () => {
    resetStore()

    Store.dispatch(
      authenticationStatus({
        isAuthenticated: true,
        userdata: {},
      })
    );

    console.log("out")
  };

  return (
    <div
      className={`flex drop-shadow-lg ${
        currentab === "authenticated" ? "" : "fixed"
      } top-0 z-10 bg-white w-full flex-row items-center justify-between h-[70px] min-w-[500px]`}
    >
      <a href="/" className="ml-10">
        <img
          className="rounded-full h-[50px] w-[50px] "
          src={logo}
          alt="logo"
        />
      </a>

      {/* Mobile Nav */}
      <GiHamburgerMenu
        onClick={() => setOpenNav(true)}
        size={30}
        className="fixed right-[14px] flex 800px:hidden"
      />

      {openNav && (
        <div
          ref={wrapperRef}
          className="absolute top-0 w-full h-[250px] min-w-[500px] z-10 bg-white"
        >
          <div
            onClick={() => setOpenNav(false)}
            className="absolute right-12 top-9"
          >
            <img height="30" width="30" src={close_icon} alt="" />
          </div>

          <div className="flex flex-col items-center justify-around h-full pt-5 min-w-[500px]">
            <a
              onClick={() => setOpenNav(false)}
              href="/"
              className="w-full hover:bg-[grey] text-center py-2 text-lg font-semibold text-blue-800 transition-all hover:text-orange-500"
            >
              Home
            </a>
            <a
              onClick={() => setOpenNav(false)}
              href="#about"
              className="text-center py-2 font-semibold text-blue-800 transition-all hover:text-orange-500"
            >
              About
            </a>
            <a
              onClick={() => setOpenNav(false)}
              href="#gallery"
              className="text-center py-2 font-semibold text-blue-800 transition-all hover:text-orange-500"
            >
              Gallery
            </a>
            <a
              onClick={() => setOpenNav(false)}
              href="/contactus"
              className="text-center py-2 font-semibold text-blue-800 transition-all hover:text-orange-500"
            >
              Contact
            </a>

            <Link
              onClick={() => setOpenNav(false)}
              to="/login"
              className="text-center py-2 font-semibold text-blue-800 transition-all hover:text-orange-500"
            >
              Login
            </Link>
          </div>
        </div>
      )}

      {/* Desktop Nav */}
      <div
        className={`hidden ${
          currentab === "authenticated" ? "justify-end" : "justify-around"
        } w-2/5 mr-12 max:w-fit 800px:flex`}
      >
        {currentab === "landingpage" && (
          <a
            href="#home"
            className="font-semibold text-blue-800 transition-all hover:text-orange-500 mr-10"
          >
            Home
          </a>
        )}

        {currentab === "landingpage" && (
          <a
            href="#about"
            className="font-semibold text-blue-800 transition-all hover:text-orange-500 mr-10"
          >
            About
          </a>
        )}

        {currentab === "landingpage" && (
          <a
            href="#gallery"
            className="font-semibold text-blue-800 transition-all hover:text-orange-500 mr-10"
          >
            Gallery
          </a>
        )}

        {currentab === "landingpage" && (
          <a
            href="#committee"
            className="font-semibold text-blue-800 transition-all hover:text-orange-500 mr-10"
          >
            Team
          </a>
        )}

        {currentab === "landingpage" && (
          <a
            href="/contactus"
            className="font-semibold text-blue-800 transition-all hover:text-orange-500 mr-10"
          >
            Contact
          </a>
        )}

        {currentab === "landingpage" ? (
          <Link
            to="/login"
            href="#contact"
            className="font-semibold text-blue-800 transition-all hover:text-orange-500 mr-10"
          >
            Login
          </Link>
        ) : currentab === "authenticated" ? (
          <Link
            to="/login"
            href="#contact"
            className={`flex h-[40px] items-center gap-2 font-semibold text-blue-800 transition-all ${
              hover && "text-orange-500"
            }`}
            onMouseEnter={handleHover}
            onMouseLeave={handleUnhover}
          >
            <MdLogout size={20} className={`${hover && "text-orange-500"}`} />
            <div
              className={`text-[14px] cursor-pointer ${
                hover && "text-orange-500"
              }`}
              onClick={ResetStore}
            >
              Log Out
            </div>
          </Link>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Navbar;
