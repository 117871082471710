import React from "react";
import "./App.css";
import "./index.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "./assets/images/cda.jpg";

// import Store from "./Redux/store";
import ProtectedRoute from "./routes/ProtectedRoute";
import {
  Dashboardpage,
  Homepage,
  Registrationpage,
  LoginPage,
} from "./routes/Routes";
import Footer from "./Layout/Footer/Footer";
import ResetPassword from "./Components/Login/Resetpassword";
import Landingpage from "./Pages/landingpage";
import Navbar from "./Components/Navbar";
import Contactus from "./Pages/Contactus/contactus";
import Sidebar from "./Components/Sidebar/Sidebar";
import { useSelector } from "react-redux";
import Occupants from "./Pages/Occupant";
import Records from "./Pages/records";
import Updaterecord from "./Components/Registration/Updaterecord";
import Payment from "./Pages/payment";
import Street from "./Pages/street";
import Wallet from "./Pages/Wallet";

const App = () => {
  const { isAuthenticated } = useSelector((state) => state.users);
  console.log(isAuthenticated)
  return (
    <div className="relative w-full h-full flex flex-col gap-3 min-w-[500px]">
      <BrowserRouter>
        <Navbar logo={logo} />
        <div className="w-full flex relative ">
          {isAuthenticated && <Sidebar />}

          <div className="w-full overflow-x-hidden ">
            <Routes>
              <Route path="/" element={<Landingpage />} />
              {/* <Route path="/sign-up" element={<Homepage />} /> */}
              <Route path="/login" element={<LoginPage />} />
              <Route path="/contactus" element={<Contactus />} />
              <Route path="/register" element={<Occupants />} />
              <Route path="/records" element={<Records />} />
              <Route path="/profile" element={<Updaterecord />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/street" element={<Street />} />
              <Route path="/wallet" element={<Wallet />} />

              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboardpage />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/activation/:activation_token"
                element={<ResetPassword />}
              />
            </Routes>
          </div>
        </div>

        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </BrowserRouter>
      {isAuthenticated ? <></> : <Footer />}
    </div>
  );
};

export default App;
