import React from "react";
import chairman from "../assets/committee/chairman.jpg";
import vice_1 from "../assets/committee/vchairman1.jpg";
import vice_2 from "../assets/committee/vchairman2.jpg";
import Secretary from "../assets/committee/secretary.jpg";
import asst_secretary from "../assets/committee/asst_secretary.jpg";
import fin_secretary from "../assets/committee/fin_secretary.jpg";
import social_secretary from "../assets/committee/social_secretary.jpg";
import treasurer from "../assets/committee/treasurer.jpg";
import asst_treasurer from "../assets/committee/asst_treasurer.jpg";
import auditor from "../assets/committee/auditor.jpg";
import welfare from "../assets/committee/welfare.jpg";
import pro from "../assets/committee/pro.jpg";



const Team = () => {
  return (
    <div className="container" data-aos="fade-up">
      <div className="section-title">
        <h2>Team</h2>
        <p>CDA Committee</p>
      </div>

      {/* <div className="row"> */}

      <div className="flex items-center justify-center flex-wrap w-full px-[50px] gap-[10px] 700px:px-[0px]">
        {/* <div className="col-lg-3 col-md-6 d-flex align-items-stretch"> */}
        <div
          className="shadow hover:shadow-md rounded-md overflow-hidden"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="member-img">
            <img
              src={chairman}
              className="img-fluid max-h-[300px] 600px:h-[200px]"
              alt=""
            />
            <div className="social">
              <a href="">
                <i className="bi bi-twitter"></i>
              </a>
              <a href="">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="">
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
          </div>
          <div className="p-3 flex flex-col">
            <span className="text-[14px] font-[600] 600px:text-[12px]">
              Mrs. Shofola Adekemi
            </span>
            <span className="text-[14px] font-[300] 600px:text-[12px]">
              Chairman
            </span>
          </div>
        </div>
        {/* </div> */}

        {/* <div className="col-lg-3 col-md-6 d-flex align-items-stretch"> */}
        <div
          className="shadow hover:shadow-md rounded-md overflow-hidden"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div className="member-img">
            <img
              src={vice_1}
              className="img-fluid max-h-[300px] 600px:h-[200px] bg-[#9D341F]"
              alt=""
            />
            <div className="social">
              <a href="">
                <i className="bi bi-twitter"></i>
              </a>
              <a href="">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="">
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
          </div>

          <div className="p-3 flex flex-col">
            <span className="text-[14px] font-[600] 600px:text-[12px]">
              Rev. Nathaniel Essien
            </span>
            <span className="text-[14px] font-[300] 600px:text-[12px]">
              Vice Chariman 1
            </span>
          </div>
        </div>
        {/* </div> */}

        <div
          className="shadow hover:shadow-md rounded-md overflow-hidden"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div className="member-img">
            <img
              src={vice_2}
              className="img-fluid max-h-[300px] 600px:h-[200px]"
              alt=""
            />
            <div className="social">
              <a href="">
                <i className="bi bi-twitter"></i>
              </a>
              <a href="">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="">
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
          </div>

          <div className="p-3 flex flex-col">
            <span className="text-[14px] font-[600] 600px:text-[12px]">
              Mr. Olumide Keyede
            </span>
            <span className="text-[14px] font-[300] 600px:text-[12px]">
              Vice Chariman 2
            </span>
          </div>
        </div>

        {/* <div className="col-lg-3 col-md-6 d-flex align-items-stretch"> */}
        <div
          className="shadow hover:shadow-md rounded-md overflow-hidden"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="member-img">
            <img
              src={Secretary}
              className="img-fluid max-h-[300px] 600px:h-[200px]"
              alt=""
            />
            <div className="social">
              <a href="">
                <i className="bi bi-twitter"></i>
              </a>
              <a href="">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="">
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
          </div>

          <div className="p-3 flex flex-col">
            <span className="text-[14px] font-[600] 600px:text-[12px]">
              Mr. Oluseun Olarinde
            </span>
            <span className="text-[14px] font-[300] 600px:text-[12px]">
              Secretary
            </span>
          </div>
        </div>
        {/* </div> */}

        <div
          className="shadow hover:shadow-md rounded-md overflow-hidden"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="member-img">
            <img
              src={asst_secretary}
              className="img-fluid max-h-[300px] 600px:h-[200px] bg-[#DCE2EF]"
              alt=""
            />
            <div className="social">
              <a href="">
                <i className="bi bi-twitter"></i>
              </a>
              <a href="">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="">
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
          </div>

          <div className="p-3 flex flex-col">
            <span className="text-[14px] font-[600] 600px:text-[12px]">
              Mr. Friday Joshua
            </span>
            <span className="text-[14px] font-[300] 600px:text-[12px]">
              Asst. Secretary
            </span>
          </div>
        </div>

        {/* <div className="col-lg-3 col-md-6 d-flex align-items-stretch"> */}
        <div
          className="shadow hover:shadow-md rounded-md overflow-hidden"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="member-img">
            <img
              src={fin_secretary}
              className="img-fluid max-h-[300px] 600px:h-[200px]"
              alt=""
            />
            <div className="social">
              <a href="">
                <i className="bi bi-twitter"></i>
              </a>
              <a href="">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="">
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
          </div>

          <div className="p-3 flex flex-col">
            <span className="text-[14px] font-[600] 600px:text-[12px]">
              Mr. Dare Olayiwola
            </span>
            <span className="text-[14px] font-[300] 600px:text-[12px]">
              Fin. Secretary
            </span>
          </div>
        </div>
        {/* </div> */}

        <div
          className="shadow hover:shadow-md rounded-md overflow-hidden"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="member-img">
            <img
              src={social_secretary}
              className="img-fluid max-h-[300px] 600px:h-[200px] bg-[#9D341F]"
              alt=""
            />
            <div className="social">
              <a href="">
                <i className="bi bi-twitter"></i>
              </a>
              <a href="">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="">
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
          </div>

          <div className="p-3 flex flex-col">
            <span className="text-[14px] font-[600] 600px:text-[12px]">
              Mrs. Ajagunsegun Blessing
            </span>
            <span className="text-[14px] font-[300] 600px:text-[12px]">
              Social Secretary
            </span>
          </div>
        </div>

        <div
          className="shadow hover:shadow-md rounded-md overflow-hidden"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="member-img">
            <img
              src={treasurer}
              className="img-fluid max-h-[300px] 600px:h-[200px]"
              alt=""
            />
            <div className="social">
              <a href="">
                <i className="bi bi-twitter"></i>
              </a>
              <a href="">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="">
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
          </div>

          <div className="p-3 flex flex-col">
            <span className="text-[14px] font-[600] 600px:text-[12px]">
              Mrs. Ogunjimi Abiodun
            </span>
            <span className="text-[14px] font-[300] 600px:text-[12px]">
              Treasurer
            </span>
          </div>
        </div>

        <div
          className="shadow hover:shadow-md rounded-md overflow-hidden"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="member-img">
            <img
              src={asst_treasurer}
              className="img-fluid max-h-[300px] 600px:h-[200px]"
              alt=""
            />
            <div className="social">
              <a href="">
                <i className="bi bi-twitter"></i>
              </a>
              <a href="">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="">
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
          </div>

          <div className="p-3 flex flex-col">
            <span className="text-[14px] font-[600] 600px:text-[12px]">
              Mrs. Sunday Akinade
            </span>
            <span className="text-[14px] font-[300] 600px:text-[12px]">
              Asst. Treasurer
            </span>
          </div>
        </div>

        <div
          className="shadow hover:shadow-md rounded-md overflow-hidden"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="member-img">
            <img
              src={auditor}
              className="img-fluid max-h-[300px] 600px:h-[200px]"
              alt=""
            />
            <div className="social">
              <a href="">
                <i className="bi bi-twitter"></i>
              </a>
              <a href="">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="">
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
          </div>

          <div className="p-3 flex flex-col">
            <span className="text-[14px] font-[600] 600px:text-[12px]">
              Mrs. Funmi Abilewa
            </span>
            <span className="text-[14px] font-[300] 600px:text-[12px]">
              Auditor
            </span>
          </div>
        </div>

        {/* <div className="col-lg-3 col-md-6 d-flex align-items-stretch"> */}
        <div
          className="shadow hover:shadow-md rounded-md overflow-hidden"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="member-img">
            <img
              src={welfare}
              className="img-fluid max-h-[300px] 600px:h-[200px]"
              alt=""
            />
            <div className="social">
              <a href="">
                <i className="bi bi-twitter"></i>
              </a>
              <a href="">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="">
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
          </div>

          <div className="p-3 flex flex-col">
            <span className="text-[14px] font-[600] 600px:text-[12px]">
              Mrs. Grace Akintoye
            </span>
            <span className="text-[14px] font-[300] 600px:text-[12px]">
              Welfare Officer
            </span>
          </div>
        </div>
        {/* </div> */}


        <div
          className="shadow hover:shadow-md rounded-md overflow-hidden"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="member-img">
            <img
              src={pro}
              className="img-fluid max-h-[300px] 600px:h-[200px]"
              alt=""
            />
            <div className="social">
              <a href="">
                <i className="bi bi-twitter"></i>
              </a>
              <a href="">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="">
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
          </div>

          <div className="p-3 flex flex-col">
            <span className="text-[14px] font-[600] 600px:text-[12px]">
              Mr. Oladipo Olatunji
            </span>
            <span className="text-[14px] font-[300] 600px:text-[12px]">
              PRO
            </span>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Team;
