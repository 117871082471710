import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PaymentRecord from "../Components/Payment/paymentRecord.jsx";
import MyLevy from "../Components/Payment/myLevy.jsx";
import { captureLevyNav } from "../Redux/Actions/buttonClick.js";
import { Store } from "../Redux/store.jsx";
import PaymentHistories from "../Components/Payment/paymentHistory.jsx";
import Navigatewallet from "../Components/Navigations/Navigatewallet.jsx";
import WalletDashboard from "../Components/Payment/walletDashboard.jsx";

const Wallet = () => {
  useEffect(() => {
    Store.dispatch(captureLevyNav(1));
  }, []);

  const { levynavtab } = useSelector((state) => state.buttonclick);

  return (
    <div className="bg-[#f9f9f9] w-full h-full flex flex-col min-w-[500px] gap-2 p-2 relative">
      <Navigatewallet />

      {levynavtab === 1 ? (
        <div className="w-full h-full relative">
          <WalletDashboard />
        </div>
      ) : levynavtab === 2 ? (
        <div className="w-full h-full relative">
          <PaymentRecord />
        </div>
      ) : levynavtab === 3 ? (
        <div className="flex w-full flex-col h-screen bg-[#f9f9f9]">
          <MyLevy />
        </div>
      ) : (
        <div className="flex w-full flex-col h-screen bg-[#f9f9f9]">
          <PaymentHistories />
        </div>
      )}
    </div>
  );
};

export default Wallet;
