// src/reducers.js
const initialState = {
  personalInfo: {},
  dependentInfo: [],
  propertyInfo: {},
};

export const enrollFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SUBMIT_PERSONAL_INFO":
      return { ...state, personalInfo: action.payload };
    case "SUBMIT_DEPENDENT_INFO":
      return { ...state, dependentInfo: action.payload };
    case "SUBMIT_PROPERTY_INFO":
      return { ...state, propertyInfo: action.payload };
    default:
      return state;
  }
};
