import React from "react";
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart }            from 'react-chartjs-2'
import { Line } from "react-chartjs-2";

const data = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
  datasets: [
    {
      label: "Income",
      data: [33, 53, 85, 41, 44, 65, 68, 72, 90],
      fill: true,
      backgroundColor: "rgba(75,192,192,0.2)",
      borderColor: "rgba(75,192,192,1)",
      responsive: true
    },
    {
      label: "Expenditure",
      data: [33, 25, 35, 51, 54, 76, 65, 60, 53],
      fill: false,
      borderColor: "#742774"
    }
  ]
};

export default function LineChart() {
  return (
    <div className="w-full text-center font-family:'Montserrat'">
      <Line data={data} />
    </div>
  );
}
