import React, { useState, useEffect } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Store } from "../../Redux/store";
import { searchFilter } from "../../Redux/Actions/pages";
import { captureLevyNav } from "../../Redux/Actions/buttonClick";
import { RiDashboardLine } from "react-icons/ri";
import { GiMoneyStack } from "react-icons/gi";
import { BiTransfer } from "react-icons/bi";
import { ImHistory } from "react-icons/im";
import "../../Pages/styles.css";

const Navigatewallet = () => {
  const { userdata } = useSelector((state) => state.users);
  console.log(userdata);
  const { clickedtab } = useSelector((state) => state.buttonclick);
  const [active, setActive] = useState(1);

  console.log(clickedtab);

  const [searchTerm, setSearchTerm] = useState("");

  const handleTab = (tab) => {
    setActive(tab);
    Store.dispatch(captureLevyNav(tab));
  };

  const handleSearchChange = (e) => {
    const searchText = e.target.value.toLowerCase();
    setSearchTerm(searchText);
    Store.dispatch(searchFilter(searchText));
  };

  return (
    <div className="w-full bg-[white] flex items-center gap-2 justify-between 850px:flex-col">
      <div className="w-fit h-auto p-1 bg-[white] shadow flex gap-1 items-start 850px:w-full">
        <div
          className={`p-2 h-full text-[12px] flex items-center box_border_class ${
            active === 1 ? "bg-[#5A322A] text-[white]" : "text-[#333]"
          }  cursor-pointer text-center 850px:grow`}
          onClick={(e) => handleTab(1)}
        >
          <RiDashboardLine fontSize={17} className="mr-3" />
          Wallet Dashboard
        </div>

        <div
          className={`p-2 h-full text-[12px] flex items-center box_border_class ${
            active === 2 ? "bg-[#5A322A] text-[white]" : "text-[#333]"
          }  cursor-pointer text-center 850px:grow`}
          onClick={(e) => handleTab(2)}
        >
          <GiMoneyStack fontSize={17} className="mr-3" />
          Fund Wallet
        </div>

        <div
          className={`p-2 h-full text-[12px] flex items-center box_border_class ${
            active === 3 ? "bg-[#5A322A] text-[white]" : "text-[#333]"
          }  cursor-pointer text-center 850px:grow`}
          onClick={(e) => handleTab(3)}
        >
          <BiTransfer fontSize={17} className="mr-3" />
          W2W Transfer
        </div>

        <div
          className={`p-2 h-full text-[12px] flex items-center box_border_class ${
            active === 3 ? "bg-[#5A322A] text-[white]" : "text-[#333]"
          }  cursor-pointer text-center 850px:grow`}
          onClick={(e) => handleTab(4)}
        >
          <ImHistory fontSize={17} className="mr-3" />
          Transaction History
        </div>
      </div>
    </div>
  );
};

export default Navigatewallet;
