import React, { useState, useEffect } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Store } from "../../Redux/store";
import { searchFilter } from "../../Redux/Actions/pages";
import { captureButtonClick } from "../../Redux/Actions/buttonClick";

const Navigate = () => {
  const { userdata } = useSelector((state) => state.users);
  console.log(userdata);
  const { clickedtab } = useSelector((state) => state.buttonclick);
  const [active, setActive] = useState(clickedtab.includes("tab_b") ? 3 : 1);

  console.log(clickedtab);

  const { tabdata } = useSelector((state) => state.tab);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    handleTab(tabdata);
    setActive(clickedtab.includes("tab_b") ? 3 : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabdata]);

  const handleTab = (tab) => {
    console.log(tab);
    setActive(tab);
    Store.dispatch(captureButtonClick(tab));
  };

  const handleSearchChange = (e) => {
    const searchText = e.target.value.toLowerCase();
    setSearchTerm(searchText);
    Store.dispatch(searchFilter(searchText));
  };

  return (
    <div className="w-full bg-[white] flex items-center gap-2 justify-between 850px:flex-col">
      <div className="w-fit h-auto py-1 bg-[white] shadow flex gap-1 items-start 850px:w-full">
        {clickedtab === "tab_c" && (
          <div
            className={`p-2 h-full text-[14px] ${
              active === 1 ? "bg-[#5A322A] text-[white]" : "text-[#333]"
            }  cursor-pointer text-center 850px:grow`}
            onClick={(e) => handleTab(1)}
          >
            Occupants Record
          </div>
        )}

        {clickedtab === "tab_c" && (
          <div
            className={`p-2 h-full text-[14px] ${
              active === 2 ? "bg-[#5A322A] text-[white]" : "text-[#333]"
            }  cursor-pointer text-center 850px:grow`}
            onClick={(e) => handleTab(2)}
          >
            Family Record
          </div>
        )}

        {clickedtab === "tab_b" && (
          <div
            className={`p-2 h-full text-[14px] ${
              active === 3 ? "bg-[#5A322A] text-[white]" : "text-[#333]"
            }  cursor-pointer text-center 850px:grow`}
            onClick={(e) => handleTab(3)}
          >
            Add New
          </div>
        )}

        {clickedtab === "tab_b" && (
          <div
            className={`p-2 h-full text-[14px] ${
              active === 4
                ? "bg-[#5A322A] text-[white]"
                : "text-[#333] border-zinc-500"
            }  cursor-pointer text-center 850px:grow`}
            onClick={(e) => handleTab(4)}
          >
            Update Profile
          </div>
        )}
      </div>

      {userdata.role.includes("Admin") && (
        <div className="w-fit flex items-start 850px:w-full">
          <div className="relative items-center flex 850px:w-full">
            <AiOutlineSearch id="searchicon" />
            <input
              className="searchfield overflow-hidden"
              type="text"
              placeholder="Search by name"
              onChange={handleSearchChange}
              value={searchTerm}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Navigate;
