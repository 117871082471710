import React, { useState, useEffect } from "react";
import axios from "axios";
import getCurrentFormattedDate from "../currentDate.jsx";
import greetBasedOnTime from "../greetFunction.jsx";
import person from "../../assets/icons/person.png";
import mastercard_icon from "../../assets/icons/mastercard.png";
import { IoIosSearch } from "react-icons/io";
import { BsArrowUpRightCircle, BsArrowDownRightCircle } from "react-icons/bs";
import getCurrentMonth from "../currentMonth.jsx";
import { TiStar, TiPlus } from "react-icons/ti";
import { BsHouses } from "react-icons/bs";
import "../../Pages/styles.css";
import { IoWalletOutline } from "react-icons/io5";
import { HiOutlineLightBulb } from "react-icons/hi2";
import { FiWifi } from "react-icons/fi";
import { FcSimCardChip } from "react-icons/fc";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Addcard from "../Addcard/Addcard.jsx";
import PaymentHistories from "../paymentHistory/paymentHistory.jsx";
import data from "./outstandingPay.json";
import LineChart from "../Chart/Linechart.js";

const WalletDashboard = () => {
  const [paymentHistory, setPaymentHistory] = useState(data.paymentHistory);
  const [addhover, setAddhover] = useState(0);
  const [active, setActive] = useState(1);
  const handleClick = (props) => {
    setActive(props);
  };

  const handleAdddCard = () => {
    setAddhover(1);
  };

  const handleAddbutton = () => {
    setAddhover(0);
  };

  return (
    <div className="flex w-full flex-col gap-3 min-h-screen min-w-[550px] relative bg-[#f9f9f9]">
      <div className="gap-5 w-full min-h-[40px] p-1 flex justify-between items-center gap-3 ">
        <div className="flex gap-3 justify-start items-center">
          <span className="font-[700] text-[17px]">MY WALLET</span>
          <span className="text-[11px] ">{getCurrentFormattedDate()}</span>
        </div>

        <div className="w-[50%] min-w-[150px] relative flex items-center">
          <input
            placeholder="Search transactions, beneficiaries and payments"
            className="placeholder:text-[12px] outline-none w-full p-2 text-[11px]"
          />

          <IoIosSearch
            fontSize={15}
            className="absolute top-[25%] right-[10px]"
          />
        </div>

        <div className="flex justify-start items-center gap-3">
          <div className="w-[35px] h-[35px] rounded-full bg-white flex justify-center items-center">
            <img src={person} alt="" className="w-[25px] h-[25px]" />
          </div>
          <div className="flex flex-col justify-center items-start">
            <span className="text-[10px]">{greetBasedOnTime()}</span>
            <span className="text-[12px] font-[600]">Oduyemi Obaloluwa</span>
          </div>
        </div>
      </div>

      <div className="w-full flex gap-3 min-h-[500px]">
        <div className="w-[60%] h-full gap-8 flex flex-col">
          <div className="w-full wrap flex gap-3 h-fit">
            <div className="w-[55%] rounded-[10px] p-3 gap-2 h-[180px] flex flex-col justify-center item-start shadow shadow-xl relative overflow-hidden balance_box">
              <span className="text-[13px] text-[white]">Current Balance</span>
              <span className="text-[29px] text-[white] font-[600]">
                ₦136,000.00
              </span>

              <div className="w-[500px] h-[500px] rounded-full absolute left-[50%] top-[20%] shadow shadow-2xl" />
            </div>

            <div className="w-[45%] flex flex-col gap-3">
              <div className="p-3 w-full h-[50%] bg-[white] rounded-[10px] shadow shadow-sm flex gap-4 flex items-center justify-start relative">
                <BsArrowUpRightCircle fontSize={30} color="#ccc" />

                <div className="flex justify-center items-start flex-col gap-2">
                  <span className="text-[13px]">Income</span>
                  <span className="text-[15px] font-[600]">₦32,098</span>
                </div>

                <span className="text-[10px] font-[500] absolute top-[5px] right-[10px]">
                  {getCurrentMonth()}
                </span>
              </div>

              <div className="p-3 w-full h-[50%] bg-[white] rounded-[10px] shadow shadow-sm flex gap-4 flex items-center justify-start relative">
                <BsArrowDownRightCircle fontSize={30} color="#ccc" />

                <div className="flex justify-center items-start flex-col gap-2">
                  <span className="text-[13px]">Expenditure</span>
                  <span className="text-[15px] font-[600]">₦12,098</span>
                </div>

                <span className="text-[10px] font-[500] absolute top-[5px] right-[10px]">
                  {getCurrentMonth()}
                </span>
              </div>
            </div>
          </div>

          <div className="w-full flex h-fit gap-2">
            <div
              className={`grow cursor-pointer shadow shadow-lg hover:shadow-2xl w-[130px] h-[130px] rounded-[5px] p-2 ${
                active === 1 ? "text-[white]" : "text-[#333]"
              } hover:translate-y-1 relative flex flex-col gap-3 items-center justify-start ${
                active === 1 ? "box1" : ""
              }`}
              onClick={() => handleClick(1)}
            >
              <TiStar
                fontSize={15}
                color={active === 1 ? "white" : "grey"}
                className="absolute top-[6px] right-[8px]"
              />
              <IoWalletOutline
                size={40}
                color={active === 1 ? "white" : "grey"}
              />
              <span className="text-[14px] font-[400] items-center">
                Levies
              </span>
              <div className="flex items-center">
                <span className="text-[12px]">Due : </span>
                <span className="ml-2 text-[12px] font-[700]">₦3000</span>
              </div>
            </div>

            <div
              className={`grow cursor-pointer shadow shadow-l hover:shadow-2xl w-[130px] h-[130px] rounded-[5px] p-2 ${
                active === 2 ? "text-[white]" : "text-[#333]"
              } hover:translate-y-1 relative flex flex-col gap-3 items-center justify-start ${
                active === 2 ? "box1" : ""
              }`}
              onClick={() => handleClick(2)}
            >
              <TiStar
                fontSize={15}
                color={active === 2 ? "white" : "grey"}
                className="absolute top-[6px] right-[8px]"
              />
              <HiOutlineLightBulb
                size={40}
                color={active === 2 ? "white" : "grey"}
              />
              <span className="text-[14px] font-[400] items-center">
                Electricity
              </span>
              <div className="flex items-center">
                <span className="text-[12px]">Due : </span>
                <span className="ml-2 text-[12px] font-[700]">₦3000</span>
              </div>
            </div>

            <div
              className={`grow cursor-pointer shadow shadow-l hover:shadow-2xl w-[130px] h-[130px] rounded-[5px] p-2 ${
                active === 3 ? "text-[white]" : "text-[#333]"
              } hover:translate-y-1 relative flex flex-col gap-3 items-center justify-start ${
                active === 3 ? "box1" : ""
              }`}
              onClick={() => handleClick(3)}
            >
              <TiStar
                fontSize={15}
                color={active === 3 ? "white" : "grey"}
                className="absolute top-[6px] right-[8px]"
              />
              <BsHouses size={40} color={active === 3 ? "white" : "grey"} />
              <span className="text-[14px] font-[400] items-center">Rent</span>
              <div className="flex items-center">
                <span className="text-[12px]">Due : </span>
                <span className="ml-2 text-[12px] font-[700]">₦3000</span>
              </div>
            </div>
          </div>

          <LineChart />
        </div>

        <div className="w-[40%] h-full flex flex-col gap-8">
          <div className="w-full overflow-y-hidden flex gap-2 items-center">
            <div className="h-[180px] w-full min-w-[350px] max-w-[450px] w-[70px] p-3 rounded-[8px] flex flex-col gap-2 justify-start items-start old_card relative">
              <div className="w-full flex items-center justify-between relative">
                <span className="text-[12px] text-[white]">debit</span>

                <FiWifi fontSize={30} color="white" className="rotate-90" />
              </div>

              <div className="w-full flex items-center justify-start">
                <FcSimCardChip
                  fontSize={60}
                  className="w-[40px] h-[38px] rounded-[3px] bg-[yellow] p-0"
                />
              </div>

              <div className="w-full flex flex-col justify-center items-start gap-1">
                <span className="text-[16px] text-white letter_spacing">
                  1234 5678 9012 3456
                </span>

                <div className="w-full flex items-center justify-center gap-3">
                  <div className="flex flex-col text-[#fff]">
                    <span className="text-[6px]">Valid</span>
                    <span className="text-[6px]">Thur</span>
                  </div>

                  <span className="text-[11px] font-[600] text-[#fff]">
                    06/25
                  </span>
                </div>
              </div>

              <span className="text-[12px] font-[600] text-white letter_spacing_others">
                ODUYEMI OBALOLUWA
              </span>

              <img
                alt=""
                className="absolute w-[80px] right-0 bottom-[-10px]"
                src={mastercard_icon}
              />
            </div>

            <div
              className="cursor-pointer h-[180px] w-[70px] p-2 rounded-[8px] flex flex-col gap-2 justify-center items-center add_card"
              onClick={handleAdddCard}
            >
              <TiPlus fontSize={20} color="white" />
              <span className="text-[10px] text-center text-[white]">
                Add Card
              </span>
            </div>
          </div>

          <div className="w-full h-fit min-h-[250px] max-h-[400px] bg-[white] rounded-[10px] gap-2 flex flex-col p-3">
            <div className="w-full flex items-center justify-between">
              <div className="flex flex-col">
                <span className="text-[18px] font-[800]">Recent Activites</span>
                <span className="text-[10px] text-[#ccc]">
                  3 recent activites have been recorded
                </span>
              </div>

              <button className="rounded-[5px] text-[10px] p-2 text-[white] box1 w-[30%] min-w-[100px]">
                Create Report
              </button>
            </div>

            <span className="text-[12px] font-[600]">Today</span>

            <div className="w-full flex flex-col gap-2">
              {paymentHistory.map((item, index) => (
                <PaymentHistories
                  name={item.name}
                  description={item.description}
                  transid={item.transid}
                  amount={item.amount}
                  status={item.status}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      {addhover === 1 ? (
        <Addcard captureAddButtonClick={handleAddbutton} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default WalletDashboard;
