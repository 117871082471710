import React, {useState} from 'react'
import './Radiofield.css'

const Radiofield = ({ name, id, value, onChange, checked, text }) => {
  return (
    <div className='radiofield-container'>
      <label htmlFor={id} className="radio-label text-[13px]">
        <input
          className="radio-input"
          type="radio"
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          checked={checked}
        />
        <span className="custom-radio" />
        {text}
      </label>
    </div>
  )
}


export default Radiofield