import React, { useState, useEffect } from "react";
import "./Registeroptionmodal.css";
import Radiofield from "../Input/Radiofield/Radiofield.js";
import {
  AiOutlineClose,
  AiOutlineCloseCircle,
  AiOutlineSearch,
} from "react-icons/ai";
import {
  captureAddButtonClick,
  captureButtonClick,
  captureListClick,
} from "../../Redux/Actions/buttonClick.js";
import { Store } from "../../Redux/store.jsx";
import { useSelector } from "react-redux";

const Registeroptionmodal = ({addnew_occupant}) => {
  const [occupantype, setOccupantype] = useState({
    primary: true,
    depend: false,
    asset: false,
  });
  const { occupant } = useSelector((state) => state.datareponse);
  const [searchData, setSearchData] = useState(occupant);
  const [data, setData] = useState(occupant);

  const [searchTerm, setSearchTerm] = useState("");
  const [hidesearchvisible, setHidesearchvisible] = useState(false);
  const [visiblesearch, setVisiblesearch] = useState(false);
  const [search, setSearch] = useState(true);

  useEffect(() => {
    setSearchData(occupant);
    setData(occupant);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [occupant]);

  const onChangeText = (e) => {
    const { name } = e.target;
    if (name === "primary") {
      setVisiblesearch(false);
      setOccupantype({ primary: true, depend: false, asset: false });
    }
    if (name === "depend") {
      setVisiblesearch(true);
      setOccupantype({ primary: false, depend: true , asset: false});
    }
    if (name === "asset") {
      setVisiblesearch(true);
      setOccupantype({ primary: false, depend: false , asset: true});
    }
  };

  const handleAddNew = () => {
    if (occupantype.primary) {
      // Primary occupant selected
      // Store.dispatch(
      //   captureAddButtonClick({
      //     occupantype: "Primary Occupant",
      //   })
      // );
      addnew_occupant("Primary Occupant")
    } else if (occupantype.depend) {
      addnew_occupant("Dependant")
    } else if (occupantype.asset) {
      addnew_occupant("Asset")
    }
  };

  const handleSearchChange = (e) => {
    // setSearchfield(true);
    const searchText = e.target.value.toLowerCase();
    setSearchTerm(searchText);
    setHidesearchvisible(true);
    const filteredResults = data.filter(
      (item) =>
        (
          item.firstName.toLowerCase() +
          " " +
          item.lastName.toLowerCase()
        ).includes(searchText) ||
        (
          item.lastName.toLowerCase() +
          " " +
          item.firstName.toLowerCase()
        ).includes(searchText) ||
        item.email.toLowerCase().includes(searchText) ||
        item.family_id.toLowerCase().includes(searchText) ||
        item.phone.toLowerCase().includes(searchText)
    );
    setSearchData(filteredResults);
    // setFormData({ ...formData, ...filteredResults[0] });
    // console.log({ ...filteredResults[0] });
  };

  const handleMouseLeave = () => {
    setHidesearchvisible(!hidesearchvisible);
  };

  const handleListselect = (incoming) => {
    setSearchTerm(incoming.firstName + " " + incoming.lastName);
    setHidesearchvisible(false);
    Store.dispatch(captureListClick(incoming));
  };

  return (
    <div className="w-full z-20 top-0 flex justify-center items-start absolute h-screen layercss">
      <div className="relative flex flex-col w-2/3 gap-5 h-2/3 items-center justify-center">
        <div className=" flex gap-3 items-center justify-center left-[10%]">
          <Radiofield
            name="primary"
            id="male"
            value="Primary Occupant"
            onChange={onChangeText}
            checked={occupantype.primary}
            text="Primary Occupant"
          />
          <Radiofield
            name="depend"
            id="female"
            value="Dependant"
            onChange={onChangeText}
            checked={occupantype.depend}
            text="Dependant"
          />
          <Radiofield
            name="asset"
            id="asset"
            value="Asset"
            onChange={onChangeText}
            checked={occupantype.asset}
            text="Asset"
          />
        </div>

        {visiblesearch && (
          <div className="w-2/3 flex justify-start items-center relative">
            <AiOutlineSearch id="searchicon" />
            <input
              className="searchfield overflow-hidden"
              type="text"
              placeholder="Search by Family-ID"
              onChange={handleSearchChange}
              value={searchTerm}
            />

            <div
              className={
                searchTerm !== "" && hidesearchvisible === true
                  ? "max-h-[30vh] bg-slate-50 shadow-sm-2 z-20 bg-[white] py-2 search_filetered_container overflow-y-auto"
                  : "hidden"
              }
              onMouseLeave={handleMouseLeave}
            >
              {searchData?.length > 0 ? (
                searchData
                  ?.filter((item) => item.occupant_type === "Primary Occupant")
                  ?.map((item, index) => (
                    <div
                      onClick={(e) => handleListselect(item)}
                      className="w-full flex items-center cursor-pointer justify-between px-2 hover:bg-[#f5f5f5]"
                      key={index}
                    >
                      <h1 className="text-[13px] px-2 py-1  whitespace-nowrap text-ellipsis overflow-hidden">
                        {item.firstName + " " + item.lastName}
                      </h1>

                      <h1 className="text-[13px] px-2 py-1 whitespace-nowrap text-ellipsis overflow-hidden font-500">
                        {item.family_id}
                      </h1>
                    </div>
                  ))
              ) : (
                <div className="text-[13px] hover:bg-[#fff] px-2 py-1 cursor-pointer whitespace-nowrap text-ellipsis overflow-hidden flex items-center justify-between">
                  <h1>Family ID Not Found</h1>
                </div>
              )}
            </div>
          </div>
        )}

        <div
          className="font-[600] w-2/3 text-center right-[11%] p-2 bg-[#fff] hover:bg-[grey] hover:text-[#fff] cursor-pointer text-[13px] shadow-md"
          onClick={handleAddNew}
        >
          Add New
        </div>
      </div>
    </div>
  );
};

export default Registeroptionmodal;
