import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  // console.log(useSelector((state) => state.user));
  const { isAuthenticated } = useSelector((state) => state.users);
  console.log(isAuthenticated)
  // if (loading === false) {
    // if (!isAuthenticated) {
    //   return <Navigate to="/login" replace />;
    // }
    return children;
  }
// };

export default ProtectedRoute;
