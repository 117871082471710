import React, { useState } from "react";
import ButtonPagenate from "../ButtonPagenate/ButtonPagenate";
import { useSelector } from "react-redux";
import "./page.css";

const Relatedoccupant = () => {
  const { occupant } = useSelector((state) => state.datareponse);
  const { userdata } = useSelector((state) => state.users);

  const relatedOccupant = occupant?.filter(
    (occupantPerson) => occupantPerson.family_id === userdata.family_id
  );

  const { searchdata } = useSelector((state) => state.searchfilter); 

  console.log(searchdata)

  const [pageNumber, setPageNumber] = useState(0);

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const itemsPerpage = 20; //Number of items to be displayed per page
  const pageCount = Math.ceil(relatedOccupant.length / itemsPerpage);
  // console.log(occupant.length)
  // console.log(pageCount)
  const pagesVisited = pageNumber * itemsPerpage;

  var newdataset = relatedOccupant.filter((filteredItem) => {
    if (searchdata === "") {
      return filteredItem;
    } else if (
      (
        filteredItem.firstName.toLowerCase() +
        " " +
        filteredItem.middleName.toLowerCase() +
        " " +
        filteredItem.lastName.toLowerCase()
      ).includes(searchdata) ||
      (
        filteredItem.lastName.toLowerCase() +
        " " +
        filteredItem.firstName.toLowerCase()
      ).includes(searchdata) ||
      filteredItem.email.toLowerCase().includes(searchdata) ||
      filteredItem.family_id.toLowerCase().includes(searchdata) ||
      filteredItem.phone.toLowerCase().includes(searchdata)
    ) {
        return filteredItem
    }
  });

  return (
    <div className="flex flex-col gap-5 max-w-screen overflow-x-auto">
      <table className="bnw-claims-table">
        <thead id="bnw-claims-table-head">
          <tr className="text-[12px] uppercase">
            <th scope="col">#SN</th>
            <th scope="col">Family ID</th>
            <th scope="col">Title</th>
            <th scope="col">Fullname</th>
            <th scope="col">Email</th>
            <th scope="col">Role</th>
            <th scope="col">Phone Number</th>
            <th scope="col">Residence Status</th>
            <th scope="col">Occupant Type</th>
            <th scope="col">Street</th>
            <th scope="col">House Nos</th>
            <th scope="col">Apartment Type</th>
            <th scope="col">Gender</th>
            <th scope="col">Marital Status</th>
            <th scope="col">Occupation</th>
            <th scope="col">Moved-In</th>
            <th scope="col">Moved-Out</th>
            <th scope="col">Living Status</th>
          </tr>
        </thead>
        <tbody>
          {newdataset
            .slice(pagesVisited, pagesVisited + itemsPerpage)
            .map((item, key) => (
              <tr key={key} className={`text-[11px] font-normal ${item?.livingStatus?.toLowerCase() === "deceased" && 'bg-[red]'}`}>
                <td data-label="#SN" className="bg-[#f9f9f9]">
                  {item?.ID}
                </td>
                <td data-label="#SN">
                  {item.family_id}
                </td>
                <td data-label="Title" className="bg-[#f9f9f9]">
                  {item.title}
                </td>
                <td
                  data-label="Fullname"
                  className="w-[100px] capitalize"
                >
                  {item.firstName + " " + item.middleName + " " + item.lastName}
                </td>
                <td data-label="Email" className="bg-[#f9f9f9]">{item.email}</td>
                <td data-label="Role">
                  {item.role}
                </td>
                <td data-label="Phone Num" className="bg-[#f9f9f9]">
                  {item.phone}
                </td>
                <td data-label="Res. Status" >
                  {item.res_status}
                </td>
                <td data-label="Occupant Type" className="bg-[#f9f9f9]" >{item.occupant_type}</td>
                <td data-label="Street">
                  {item.street_name}
                </td>
                <td data-label="House Num" className="bg-[#f9f9f9]">{item.house_number}</td>
                <td data-label="Apartment Type">
                  {item.apartment_type}
                </td>
                <td data-label="Gender" className="bg-[#f9f9f9]">{item.gender}</td>
                <td data-label="Marital Status">
                  {item.maritalStatus}
                </td>
                <td data-label="Occupation" className="bg-[#f9f9f9]">{item.occupation}</td>
                <td data-label="Moved-In">
                  {item.moveInDate}
                </td>
                <td data-label="Moved-Out" className="bg-[#f9f9f9]">{item.moveOutDate}</td>
                <td data-label="Living Status">
                  {item.livingStatus}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <div className="w-fit">
        {/* <button id='addbitn' onClick={handleAddButtn}>Add</button> */}
        <ButtonPagenate
          updatedPageNum={newPagenum}
          pageCount={pageCount}
          pageNumber={pageNumber}
          previousLabel="Prev"
          nextLabel="Next"
        />
      </div>
    </div>
  );
};

export default Relatedoccupant;
