import React, { useState } from "react";
import Navigatestreet from "../Components/Navigations/Navigatestreet";
import StreetList from "../Components/Street/StreetList";
import { useSelector } from "react-redux";
import HousesList from "../Components/Houses/HousesList";
import AddStreet from "../Components/AddStreet/AddStreet";
import AddHouse from "../Components/Addhouse/Addhouse";

const Street = () => {
  const [addhover, setAddhover] = useState(0);
  const { streetnavtab } = useSelector((state) => state.buttonclick);

  const handleAddbutton = (incoming) => {
    setAddhover(incoming);
  };

  console.log(streetnavtab);

  return (
    <div className="m-3 pr-6 w-full min-h-screen flex flex-col gap-3 min-w-[550px] relative">
      <Navigatestreet />
      {streetnavtab === 1 ? (
        <div className="flex w-full flex-col h-screen bg-[#f9f9f9]">
          <StreetList captureAddButtonClick={handleAddbutton} />
        </div>
      ) : streetnavtab === 2 ? (
        <div className="flex w-full flex-col h-screen bg-[#f9f9f9]">
          <HousesList
            captureAddButtonClick={handleAddbutton}
            togglebar={addhover}
          />
        </div>
      ) : (
        <div className="flex w-full flex-col h-screen bg-[#f9f9f9]">
          <StreetList captureAddButtonClick={handleAddbutton} />
        </div>
      )}

      {addhover === 1 ? (
        <AddStreet captureAddButtonClick={handleAddbutton} />
      ) : addhover === 2 ? (
        <AddHouse captureAddButtonClick={handleAddbutton} />
      ) : addhover === 3 ? (
        <AddStreet captureAddButtonClick={handleAddbutton} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Street;
