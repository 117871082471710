const Assetsdata = [
    {
        ID: 1,
        name: 'Vehicle',
        vehicle_type: [
            {
                ID: 1,
                name: 'SUV',
                vehicle_make: [
                    {
                        ID: 1,
                        name: 'Toyota',
                        model: [
                            {
                                ID: 2,
                                name: 'Highlinder'
                            },
                            {
                                ID: 4,
                                name: 'Sequoia'
                            },
                            {
                                ID: 6,
                                name: 'Land Cruiser Prado'
                            },
                            {
                                ID: 8,
                                name: '4Runner SR5'
                            },
                            {
                                ID: 10,
                                name: 'RAV4'
                            },
                            {
                                ID: 12,
                                name: 'Venza'
                            }
                        ]
                    },
                    {
                        ID: 2,
                        name: 'Lexus',
                        model: [
                            {
                                ID: 1,
                                name: 'UX HYBRID'
                            },
                            {
                                ID: 3,
                                name: 'NX'
                            },
                            {
                                ID: 5,
                                name: 'NX HYBRID'
                            },
                            {
                                ID: 7,
                                name: 'NX PLUG-IN HYBRID EV'
                            },
                            {
                                ID: 9,
                                name: 'GX'
                            },
                            {
                                ID: 11,
                                name: 'LX'
                            },
                            {
                                ID: 13,
                                name: 'RX'
                            }
                        ]
                    },
                    {
                        ID: 3,
                        name: 'Pegeuot',
                        model: [
                            {
                                ID: 1,
                                name: 'Camry'
                            },
                            {
                                ID: 2,
                                name: 'Highlinder'
                            },
                            {
                                ID: 3,
                                name: 'Corolla'
                            },
                            {
                                ID: 4,
                                name: 'Sienna'
                            }
                        ]
                    }
                ]
            },
            {
                ID: 2,
                name: 'Sedan',
                vehicle_make: [
                    {
                        ID: 1,
                        name: 'Toyota',
                        model: [
                            {
                                ID: 1,
                                name: 'Camry'
                            },
                            {
                                ID: 3,
                                name: 'Corolla'
                            },
                            {
                                ID: 5,
                                name: 'Avalon'
                            },
                            {
                                ID: 7,
                                name: 'Corolla Hybrid'
                            },
                            {
                                ID: 9,
                                name: 'Mirai'
                            }
                        ]
                    },
                    {
                        ID: 2,
                        name: 'Lexus',
                        model: [
                            {
                                ID: 2,
                                name: 'IS'
                            },
                            {
                                ID: 4,
                                name: 'IS 500'
                            },
                            {
                                ID: 6,
                                name: 'ES HYBRID'
                            },
                            {
                                ID: 8,
                                name: 'ES'
                            },
                            {
                                ID: 10,
                                name: 'LS HYBRID'
                            },
                            {
                                ID: 12,
                                name: 'LS'
                            }
                        ]
                    },
                    {
                        ID: 3,
                        name: 'Pegeuot',
                        model: [
                            {
                                ID: 1,
                                name: 'Camry'
                            },
                            {
                                ID: 2,
                                name: 'Highlinder'
                            },
                            {
                                ID: 3,
                                name: 'Corolla'
                            },
                            {
                                ID: 4,
                                name: 'Sienna'
                            }
                        ]
                    }
                ]
            },
        ]
    },
    {
        ID: 2,
        name: 'Motocycle'
    },
    {
        ID: 2,
        name: 'Bicycle'
    }
]

export default Assetsdata