import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  submitPersonalInfo,
  submitDependentInfo,
  submitPropertyInfo,
} from "../../Redux/Actions/Enrollform.jsx";
import Stepper from "react-stepper-horizontal";
import UploadImg from "../Imageupload/UploadImg.jsx";
import ResidentJSON from "./residentJson.js";
import Singleselect from "../Singleselect/Singleselect.js";
import { genderJSON, titleJSON } from "./genderJson.js";
import { MdAddCircleOutline } from "react-icons/md";
import {
  livingStatusJSON,
  maritalStatusJSON,
  relationshipStatus,
  rolesJSON,
} from "./maritalststuaJSON.js.js";
import apratmentJSON from "./apartmentJson.js";
import Assetsdata from "../../data/assets.js";
import Lottie from "lottie-react";
import animationData from "../../assets/animations/107043-success.json";
import { BsFillCheckCircleFill } from "react-icons/bs";
import streetJSON from "./streetJson.js";
import { FaPlus } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import "./searchstyle.css";
import axios from "axios";
import { server } from "../../server.js";
import { toast } from "react-toastify";
import Loader from "../Loading/Loading.js";
import { Store } from "../../Redux/store.jsx";
import { clickedTab } from "../../Redux/Actions/pages.js";
import { getAllOccupants } from "../../Redux/Actions/dataResponse.js";

const Updaterecord = () => {
  // const [cookies] = useCookies(["Token"]);
  const { occupant } = useSelector((state) => state.datareponse);
  const { userdata } = useSelector((state) => state.users);

  const [activeStep, setActiveStep] = useState(0);
  // const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(occupant);
  const [data, setData] = useState(occupant);
  const [loader, setLoader] = useState(false);
  const [asset, setAsset] = useState(null);
  const [carmake, setCarmake] = useState(null);
  const [cartype, setCartype] = useState(null);

  const [occuptype, setOccuptype] = useState(false);
  const [occupantType, setOccupantType] = useState("");
  const [formData, setFormData] = useState({
    // Define your personal information fields here
    family_id: "",
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    image_url: "",
    res_status: "",
    occupant_type: "",
    street_name: "",
    house_number: "",
    apartment_type: "",
    gender: "",
    maritalStatus: "",
    occupation: "",
    role: "",
    livingStatus: "",
    // ...
  });

  const [assetdata, setAssetdata] = useState({
    // Define your Asset information fields here
    asset: "",
    asset_type: "",
    engine_num: "",
    plate_num: "",
    make: "",
    model: "",
    year: "",
    color: "",
    mileage: "",
    engineType: "",
    vin: "",
    chasis_num: "",
    displacement: "",
    horsepower: "",
    asset_owner: 0,
    // ...
  });

  const fieldsData = {
    family_id: userdata.family_id,
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    image_url: "",
    res_status: userdata.res_status,
    occupant_type: "Dependant",
    street_name: userdata.street_name,
    house_number: userdata.house_number,
    apartment_type: userdata.apartment_type,
    gender: "",
    maritalStatus: "",
    occupation: "",
    role: "User",
    livingStatus: "",
  };

  const [assetArray, setAssetArray] = useState([]);
  const [formArray, setFormArray] = useState([]);

  const [rowcount, setRowcount] = useState(1);
  const [newoccupant, setNewoccupant] = useState(1);

  useEffect(() => {
    // setActiveStep(0);
    if (activeStep === 1) {
      setAssetArray((oldArray) => [...oldArray, { ...assetdata }]);
    }

    setFormData({ ...formData, ...userdata });
    if (formArray.length < 1) {
      setFormArray((oldArray) => [...oldArray, { ...userdata }]);
    }

    setOccupantType("Primary Occupant");
    setSearchData(occupant);
    setData(occupant);

    setAssetArray([{ ...assetdata }]);
    // setFormArray([{ ...formData }]);

    setFormData({
      family_id: userdata.family_id,
      title: "",
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      image_url: "",
      res_status: userdata.res_status,
      occupant_type: "Dependant",
      street_name: userdata.street_name,
      house_number: userdata.house_number,
      apartment_type: userdata.apartment_type,
      gender: "",
      maritalStatus: "",
      occupation: "",
      role: "User",
      livingStatus: "",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [occupant, activeStep, assetdata]);

  const allOccupants = useSelector((state) => state.datareponse);

  const relatedOccupant = allOccupants?.occupant?.filter(
    (occupantPerson) => occupantPerson.family_id === userdata.family_id
  );

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (activeStep === 0) {
      for (let index = 0; index < formArray.length; index++) {
        const element = formArray[index];

        if (
          element.firstName !== "" &&
          element.lastName !== "" &&
          element.email !== "" &&
          element.phone !== ""
        ) {
          if (index + 1 === formArray.length) {
            saveFirstSatage();
            break;
          }
        } else {
          toast.warning("Please Fill all required fields!");
          break;
        }
      }
    } else if (activeStep === 1) {
      saveSecondSatage();
    }

    // console.log(formData);
    console.log(formArray);
  };

  const handleClick = () => {
    setRowcount(rowcount + 1);
    setFormArray((oldArray) => [...oldArray, { ...assetdata }]);
    setAssetdata({
      // Define your Asset information fields here
      asset: "",
      asset_type: "",
      engine_num: "",
      plate_num: "",
      make: "",
      model: "",
      year: "",
      color: "",
      mileage: "",
      engineType: "",
      vin: "",
      chasis_num: "",
      displacement: "",
      horsepower: "",
      asset_owner: 0,
      // ...
    });
  };

  // Remove from Asset List
  const handleReduceClick = () => {
    if (assetArray.length > 1) {
      setAssetArray((prevAssets) => {
        const updatedAssets = [...prevAssets];
        updatedAssets.pop();
        return updatedAssets;
      });
    }
  };

  const handleStepsBackward = () => {
    setActiveStep(activeStep - 1);
  };

  // Submit Occupant FormsaveFirstSatage
  const saveFirstSatage = async () => {
    console.log(formArray);

    // if (formArray.length > 1) {
    //   createOccupantRecord();
    // } else {
    //   updateOccupantRecord(formArray[0]);
    // }

    createUpdateOccupantRecord();
  };

  // Submit Asset Form
  const saveSecondSatage = async () => {
    try {
      var config = {
        method: "POST",
        url: `${server}/assets`,
        data: assetArray,
      };

      await axios(config).then((response) => {
        if (response.data.status === 201) {
          toast.success(response.data.message);
          setActiveStep(activeStep + 1);
        }
      });
    } catch (e) {
      toast.success(e.response.data.message);
    }
  };

  const updateOccupantRecord = async (recordObject) => {
    setLoader(true);
    try {
      var config = {
        method: "PUT",
        url: `${server}/occupants/${recordObject.ID}`,
        data: recordObject,
      };

      // console.log(config);
      await axios(config).then((response) => {
        if (response.data.status === 200) {
          setLoader(false);
          toast.success(response.data.message);
          setActiveStep(activeStep + 1);
        }
      });
    } catch (e) {
      console.log(e);
      // toast.success(e.response.data.message);
    }
  };

  const createUpdateOccupantRecord = async () => {
    setLoader(true);
    try {
      var config = {
        method: "POST",
        url: `${server}/occupants/createupdate`,
        data: formArray,
      };

      // console.log(config);
      await axios(config).then((response) => {
        console.log(response);
        if (response.status === 201) {
          Store.dispatch(getAllOccupants(response.data.updatedRecords[0]));
          setLoader(false);
          toast.success(response.data.message);
          setActiveStep(activeStep + 1);
        }
      });
    } catch (e) {
      console.log(e);
      // toast.success(e.response.data.message);
    }
  };

  const createOccupantRecord = async () => {
    setLoader(true);
    try {
      var config = {
        method: "POST",
        url: `${server}/occupants`,
        data: formArray,
      };

      // console.log(config);
      await axios(config).then((response) => {
        if (response.data.status === 200) {
          setLoader(false);
          toast.success(response.data.message);
          setActiveStep(activeStep + 1);
        }
      });
    } catch (e) {
      console.log(e);
      // toast.success(e.response.data.message);
    }
  };

  // const selectOccupant = (incoming) => {
  //   setFormData({ ...formData, occupant_type: incoming });
  //   if (incoming === "Dependant") {
  //     setOccuptype(true);
  //   } else {
  //     setOccuptype(false);
  //   }
  // };

  const handleSelected = (incoming, e, formindex) => {
    // console.log(incoming, e, formindex);
    setFormData({ ...formData, [incoming]: e });

    formArray.map((item, index) => {
      if (index === formindex) {
        item[incoming] = e;
      }
    });
  };

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    const updatedAssets = [...assetArray];
    updatedAssets[index] = {
      ...updatedAssets[index],
      [name]: value,
    };
    setAssetArray(updatedAssets);
  };

  const handleSelectedAsset = (incoming, e) => {
    // setAssetdata({ ...assetdata, [incoming]: e });
    const value = e;
    const name = incoming.name;
    const index = incoming.index;
    const updatedAssets = [...assetArray];
    updatedAssets[index] = {
      ...updatedAssets[index],
      [name]: value,
    };
    setAssetArray(updatedAssets);

    if (e === "Vehicle") {
      setAsset("vehicle");
    } else if (e === "Motocycle") {
      setAsset("motocycle");
    } else if (e === "Bicycle") {
      setAsset("bicycle");
    } else if (e === "Toyota") {
      setCarmake("toyota");
    } else if (e === "Lexus") {
      setCarmake("lexus");
    } else if (e === "Pegeuot") {
      setCarmake("pegeuot");
    } else if (e === "SUV") {
      setCartype("suv");
    } else if (e === "Sedan") {
      setCartype("sedan");
    }
  };

  const steps = [
    { title: "Personal Information" },
    { title: "Asset Information" },
    { title: "Confirmation" },
  ];

  const handleToggleSelection = () => {
    // setLoaderror({
    //     statusid: 0,
    //     message: ''
    // })
  };

  const handledirectoryUpdate = (incoming) => {
    setFormData({ ...formData, image_url: incoming });
    // console.log("image url : ", incoming);
  };

  const handleRefresh = () => {
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  };

  const style = {
    height: 200,
  };

  const AddDependent = () => {
    setNewoccupant(newoccupant + 1);
    setFormData({
      family_id: userdata.family_id,
      title: "",
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      image_url: "",
      res_status: userdata.res_status,
      occupant_type: "Dependant",
      street_name: userdata.street_name,
      house_number: userdata.house_number,
      apartment_type: userdata.apartment_type,
      gender: "",
      maritalStatus: "",
      occupation: "",
      role: "User",
      livingStatus: "",
    });
    setFormArray([...formArray, { ...fieldsData }]);
  };

  const RmvDependent = () => {
    if (formArray.length > 1) {
      setFormArray((prevForms) => {
        const updatedForms = [...prevForms];
        updatedForms.pop();
        return updatedForms;
      });
    }
  };

  const handleformChange = (e, formindex) => {
    const { name, value } = e.target;

    // console.log(formindex);
    // console.log(name);

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    // setFormArray(
    formArray.map((item, index) => {
      if (index === formindex) {
        item[name] = value;
      }
    });
  };

  return (
    <>
      <div className="flex w-full flex-col bg-[#f9f9f9] gap-2 m-3 min-h-screen min-w-[550px]relative">
        <div className="flex relative items-center justify-center gap-5">
          {/* <img src={Logo} className="w-[60px] h-[60px] rounded-full" alt="" /> */}

          {/* <div className="flex flex-col">
            <h2 className="mt-2 text-2xl font-bold text-[#333]-900 text-center">
              OMOTOYE ESTATE
            </h2>
            <h2 className=" text-xl text-[#ccc]-500 text-center">
              Community Development Association
            </h2>
          </div> */}
        </div>

        <div className="w-full flex items-center justify-center px-4">
          <Stepper steps={steps} activeStep={activeStep} />
        </div>

        <div className="px-[40px] py-[20px]">
          {activeStep === 0 ? (
            <div className="h-full flex flex-col gap-5">
              {formArray.map((items, index) => (
                <div key={index} className="pb-4">
                  <div className="flex flex-col gap-2">
                    <div className="flex justify-center mb-3">
                      <UploadImg
                        fetchedurl={formData.image_url}
                        onfocus={handleToggleSelection}
                        directory={handledirectoryUpdate}
                      />
                    </div>

                    <div className="w-full flex-wrap flex items-center justify-between gap-2">
                      <div className="flex flex-col w-[70px] gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px] text-[gray]">
                          <label>Title</label>
                          <p className="text-[red]">*</p>
                        </div>

                        <Singleselect
                          dd_value={formArray[index]?.title}
                          socialjs={titleJSON}
                          selectedSocVal={(e) =>
                            handleSelected("title", e, index)
                          }
                        />
                      </div>

                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px] text-[gray]">
                          <label>Firstname</label>
                          <p className="text-[red]">*</p>
                        </div>

                        <input
                          type="text"
                          placeholder="First Name"
                          value={formArray[index]?.firstName}
                          name="firstName"
                          required
                          onChange={(e) => handleformChange(e, index)}
                          className="placeholder-gray-500 placeholder-opacity-25 ... border min-w-[120px] grow p-2 text-[13px] rounded-sm w-full min-w-[200px] outline-none"
                        />
                      </div>

                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px] text-[gray] text-[gray]">
                          <label>Middlename</label>
                        </div>

                        <input
                          type="text"
                          placeholder="Middle Name"
                          value={formArray[index]?.middleName}
                          name="middleName"
                          onChange={(e) => handleformChange(e, index)}
                          className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                        />
                      </div>

                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px] text-[gray]">
                          <label>Lastname</label>
                          <p className="text-[red]">*</p>
                        </div>

                        <input
                          type="text"
                          placeholder="Last Name"
                          value={formArray[index]?.lastName}
                          name="lastName"
                          required
                          onChange={(e) => handleformChange(e, index)}
                          className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-wrap items-center justify-between gap-2">
                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px] text-[gray]">
                          <label>Email</label>
                          <p className="text-[red]">*</p>
                        </div>
                        <input
                          type="email"
                          placeholder="Email"
                          value={formArray[index]?.email}
                          name="email"
                          required
                          onChange={(e) => handleformChange(e, index)}
                          className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                        />
                      </div>

                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px] text-[gray]">
                          <label>Telephone</label>
                          <p className="text-[red]">*</p>
                        </div>
                        <input
                          type="text"
                          placeholder="Phone number"
                          value={formArray[index]?.phone}
                          name="phone"
                          required
                          onChange={(e) => handleformChange(e, index)}
                          className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                        />
                      </div>
                    </div>

                    {index < 1 && (
                      <div className="w-full flex flex-wrap items-center justify-between gap-2">
                        <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                          <div className="flex items-center gap-2 text-[13px] text-[gray]">
                            <label>Street Name</label>
                            <p className="text-[red]">*</p>
                          </div>

                          <Singleselect
                            dd_value={formArray[0]?.street_name}
                            socialjs={streetJSON}
                            selectedSocVal={(e) =>
                              handleSelected("street_name", e, index)
                            }
                            placeholder="Select Street"
                          />
                        </div>

                        <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                          <div className="flex items-center gap-2 text-[13px] text-[gray]">
                            <label>House Number</label>
                            <p className="text-[red]">*</p>
                          </div>

                          <input
                            type="text"
                            placeholder="House Number"
                            value={formArray[0]?.house_number}
                            name="house_number"
                            required
                            onChange={(e) => handleformChange(e, index)}
                            className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                          />
                        </div>

                        <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                          <div className="flex items-center gap-2 text-[13px] text-[gray]">
                            <label>Apartment Type</label>
                            <p className="text-[red]">*</p>
                          </div>

                          <Singleselect
                            dd_value={formArray[0]?.apartment_type}
                            socialjs={apratmentJSON}
                            selectedSocVal={(e) =>
                              handleSelected("apartment_type", e, index)
                            }
                            placeholder="Select Apartment type"
                          />
                        </div>
                      </div>
                    )}

                    <div className="w-full flex flex-wrap items-center justify-between gap-2">
                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px] text-[gray]">
                          <label>Gender</label>
                          <p className="text-[red]">*</p>
                        </div>

                        <Singleselect
                          dd_value={formArray[index]?.gender}
                          socialjs={genderJSON}
                          selectedSocVal={(e) =>
                            handleSelected("gender", e, index)
                          }
                          placeholder="Select Gender"
                        />
                      </div>

                      {index < 1 && (
                        <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                          <div className="flex items-center gap-2 text-[13px] text-[gray]">
                            <label>Resident status</label>
                            <p className="text-[red]">*</p>
                          </div>

                          <Singleselect
                            dd_value={formArray[index]?.res_status}
                            socialjs={ResidentJSON}
                            selectedSocVal={(e) =>
                              handleSelected("res_status", e, index)
                            }
                            placeholder="Select Resident status"
                          />
                        </div>
                      )}
                    </div>

                    <div className="w-full flex flex-wrap items-center justify-between gap-2">
                      {occuptype && (
                        <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                          <div className="flex items-center gap-2 text-[13px] text-[gray]">
                            <label>Primary Occupant Email</label>
                            <p className="text-[red]">*</p>
                          </div>
                          <input
                            type="email"
                            placeholder="Primary Occupant email"
                            value={formData.primary_occupant_email}
                            name="primary_occupant_email"
                            required
                            onChange={(e) => handleformChange(e, index)}
                            className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                          />
                        </div>
                      )}
                    </div>

                    <div className="w-full flex flex-wrap items-center justify-between gap-2">
                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px] text-[gray]">
                          <label>Martial Status</label>
                          <p className="text-[red]">*</p>
                        </div>

                        <Singleselect
                          dd_value={formArray[index]?.maritalStatus}
                          socialjs={maritalStatusJSON}
                          selectedSocVal={(e) =>
                            handleSelected("maritalStatus", e, index)
                          }
                          placeholder="Select Marital Status"
                        />
                      </div>

                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px] text-[gray]">
                          <label>Occupation</label>
                          <p className="text-[red]">*</p>
                        </div>
                        <input
                          type="text"
                          placeholder="Occupation"
                          value={formArray[index]?.occupation}
                          name="occupation"
                          required
                          onChange={(e) => handleformChange(e, index)}
                          className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-wrap items-center justify-between gap-2">
                      {index > 0 && (
                        <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                          <div className="flex items-center gap-2 text-[13px] text-[gray]">
                            <label>Relationship</label>
                            <p className="text-[red]">*</p>
                          </div>

                          <Singleselect
                            dd_value={formArray[index]?.relationship}
                            socialjs={relationshipStatus}
                            selectedSocVal={(e) =>
                              handleSelected("maritalStatus", e, index)
                            }
                            placeholder="Select Marital Status"
                          />
                        </div>
                      )}

                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px] text-[gray]">
                          <label>Living Status</label>
                          <p className="text-[red]">*</p>
                        </div>
                        <Singleselect
                          dd_value={formArray[index]?.livingStatus}
                          socialjs={livingStatusJSON}
                          selectedSocVal={(e) =>
                            handleSelected("livingStatus", e, index)
                          }
                          placeholder="Living Status"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : activeStep === 1 ? (
            <div className="h-full flex flex-col gap-5">
              {assetArray.map((items, index) => (
                <div key={index} className="p-3 bg-[#f9f5f9] border">
                  <div className="flex flex-col gap-2">
                    <div className="w-full flex-wrap flex items-center justify-between gap-2">
                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px]">
                          <label>Asset</label>
                        </div>

                        <Singleselect
                          // dd_value={formData.asset}
                          socialjs={Assetsdata}
                          selectedSocVal={(e) =>
                            handleSelectedAsset({ name: "asset", index }, e)
                          }
                          placeholder="Select Asset"
                        />
                      </div>

                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px]">
                          <label>Type</label>
                        </div>

                        <Singleselect
                          // dd_value={ass.type}
                          socialjs={Assetsdata[0].vehicle_type}
                          selectedSocVal={(e) =>
                            handleSelectedAsset(
                              { name: "asset_type", index },
                              e
                            )
                          }
                          placeholder="Select Type"
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-wrap items-center justify-between gap-2">
                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px]">
                          <label>Make</label>
                        </div>

                        <Singleselect
                          socialjs={
                            cartype === "suv"
                              ? Assetsdata[0].vehicle_type[0].vehicle_make
                              : Assetsdata[0].vehicle_type[1].vehicle_make
                          }
                          selectedSocVal={(e) =>
                            handleSelectedAsset({ name: "make", index }, e)
                          }
                          placeholder="Select make"
                        />
                      </div>

                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px]">
                          <label>Model</label>
                        </div>
                        <Singleselect
                          socialjs={
                            carmake === "toyota" && cartype === "suv"
                              ? Assetsdata[0].vehicle_type[0].vehicle_make[0]
                                  .model
                              : carmake === "lexus" && cartype === "suv"
                              ? Assetsdata[0].vehicle_type[0].vehicle_make[1]
                                  .model
                              : carmake === "toyota" && cartype === "sedan"
                              ? Assetsdata[0].vehicle_type[1].vehicle_make[0]
                                  .model
                              : Assetsdata[0].vehicle_type[1].vehicle_make[1]
                                  .model
                          }
                          selectedSocVal={(e) =>
                            handleSelectedAsset({ name: "model", index }, e)
                          }
                          placeholder="Select Model"
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-wrap items-center justify-between gap-2">
                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px]">
                          <label>Engine Number</label>
                        </div>

                        <input
                          type="text"
                          placeholder="Engine Number"
                          value={items.engine_num || ""}
                          name="engine_num"
                          onChange={(e) => handleChange(e, index)}
                          className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-wrap items-center justify-between gap-2">
                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px]">
                          <label>Plate Number</label>
                        </div>
                        <input
                          type="text"
                          placeholder="Plate number"
                          value={items.plate_num || ""}
                          name="plate_num"
                          onChange={(e) => handleChange(e, index)}
                          className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-wrap items-center justify-between gap-2">
                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px]">
                          <label>Color</label>
                        </div>

                        <input
                          type="text"
                          placeholder="color"
                          value={items.color || ""}
                          name="color"
                          onChange={(e) => handleChange(e, index)}
                          className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                        />
                      </div>

                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px]">
                          <label>Year</label>
                        </div>
                        <input
                          type="text"
                          placeholder="year"
                          value={items.year || ""}
                          name="year"
                          onChange={(e) => handleChange(e, index)}
                          className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div className="flex gap-2 items-center my-2">
                <div
                  onClick={handleClick}
                  className="p-2 rounded-full flex items-center justify-center bg-[#1F3864] cursor-pointer"
                >
                  <FaPlus size={13} color="#fff" />
                </div>

                <div
                  onClick={handleReduceClick}
                  className="p-2 rounded-full flex items-center justify-center bg-[#1F3864] cursor-pointer"
                >
                  <MdDeleteOutline size={13} color="#fff" />
                </div>
              </div>
            </div>
          ) : activeStep === 2 ? (
            <div className="h-full w-full flex justify-center items-center flex-col gap-5">
              <Lottie animationData={animationData} style={style} />

              <div className="w-full flex items-center justify-center gap-3">
                <BsFillCheckCircleFill color="green" size={25} />
                <h5 className="text-center text-[16px] font-[600] text-[green]">
                  Registration Complete! 😍
                </h5>
              </div>

              <div
                onClick={handleRefresh}
                className="px-5 py-2 text-[#fff] cursor-pointer bg-[#007FFF] rounded text-[14px] hover:shadow-sm"
              >
                Begin Fresh
              </div>
            </div>
          ) : null}

          <div className="flex gap-2 justify-between items-center w-full">
            {activeStep === 0 && (
              <>
                <button
                  className="px-3 cursor-pointer py-2 bg-[grey] text-[white] text-[13px] font-[600] mt-5 mr-2 whitespace-nowrap"
                  onClick={AddDependent}
                >
                  Add Dependant
                </button>

                <button
                  className="px-3 cursor-pointer py-2 bg-[grey] text-[white] text-[13px] font-[600] mt-5 mr-2 whitespace-nowrap"
                  onClick={RmvDependent}
                >
                  Remove Dependant
                </button>
              </>
            )}

            {activeStep !== 0 && activeStep !== steps.length - 1 && (
              <button
                className="px-3 cursor-pointer py-2 bg-[grey] text-[white] text-[13px] font-[600] mt-5 mr-2"
                onClick={handleStepsBackward}
              >
                Previous
              </button>
            )}

            <div className="flex gap-1 items-center w-full justify-end">
              {activeStep !== steps.length - 1 && (
                <button
                  disabled={loader ? true : false}
                  className="flex items-center px-3 cursor-pointer py-2 bg-[grey] text-[white] text-[13px] font-[600] mt-5"
                  onClick={handleFormSubmit}
                >
                  {loader && (
                    <Loader
                      type="spin"
                      color="white"
                      text=""
                      height="100%"
                      width="100%"
                    />
                  )}
                  Save & Continue
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Updaterecord;
