import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { GrEdit } from "react-icons/gr";
import {
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";
import { getAllLevy } from "../../Redux/Actions/dataResponse";
import { Store } from "../../Redux/store";
import { server } from "../../server";
import Singleselect from "../Singleselect/Singleselect";
import { TargetDemograph, TargetSeason } from "../Registration/maritalststuaJSON.js";
import Toggles from "../Togglebutton/Togglebuttons.js";
import ButtonPagenate from "../ButtonPagenate/ButtonPagenate.js";


const CreatePayment = () => {
  const { levy } = useSelector((state) => state.datareponse);
  const [pageNumber, setPageNumber] = useState(0);
  const [paymentdata, setPaymentdata] = useState([]);
  const { searchdata } = useSelector((state) => state.searchfilter);
  const [editrow, setEditrow] = useState(1);
  const [updaterow, setUpdaterow] = useState(0);
  const [statusupdate, setStatusupdate] = useState(0);

  const newPagenum = (num) => {
    setPageNumber(num);
  };
  const [uploading, setUploading] = useState(false);
  const [edittoggle, setEdittoggle] = useState(true);

  useEffect(() => {
    setPaymentdata(levy);
  }, [levy]);

  const [formData, setFormData] = useState({
    levylabel: "",
    targetdemograph: "",
    amount: "",
    target_season: "",
    status: 0,
  });

  const [hiddenformData, setHiddenformData] = useState({
    levylabel: "",
    targetdemograph: "",
    amount: "",
    target_season: "",
    status: 0,
  });

  useEffect(() => {
    let incomingtext = levy.filter((item) => item.ID === editrow)[0];

    setFormData(updateLevyHooks(formData, incomingtext));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editrow, levy]);

  useEffect(() => {
    let incomingtext = levy.filter((item) => item.ID === updaterow)[0];

    setHiddenformData(updateLevyHooks(hiddenformData, incomingtext));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updaterow, levy]);

  const updateLevyHooks = (formData, result) => {
    const updatedFormData = { ...formData };

    for (const key in updatedFormData) {
      if (result?.hasOwnProperty(key)) {
        updatedFormData[key] = result[key];
      }
    }

    return updatedFormData;
  };

  const itemsPerpage = 20; //Number of items to be displayed per page
  const pageCount = Math.ceil(paymentdata?.length / itemsPerpage);
  const pagesVisited = pageNumber * itemsPerpage;

  const [loading, setLoading] = useState(false);

  const handleSubmitform = async (e) => {
    setLoading(true);
    e.preventDefault();

    var config = {
      method: "POST",
      url: `${server}/levy`,
      data: formData,
    };

    await axios(config)
      .then((res) => {
        Store.dispatch(getAllLevy(res.data.levy));
        toast.success("Created Successfully");
        setEdittoggle(true);
        setLoading(false);
        clearfields();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data);
      });
  };

  const handleUpdateform = async (e) => {
    setLoading(true);
    e.preventDefault();

    var config = {
      method: "PUT",
      url: `${server}/levy/${editrow}`,
      data: formData,
    };

    await axios(config)
      .then((res) => {
        Store.dispatch(getAllLevy(res.data.levy));
        toast.success("Created Successfully");
        setEdittoggle(true);
        setEditrow(false);
        setLoading(false);
        clearfields();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.res?.data);
      });
  };

  const handleActivate = async () => {
    // Activation Successful
    // Store.dispatch(toggleButton(1));
    setUploading(true);
    var config = {
      method: "PUT",
      url: `${server}/levy/${updaterow}`,
      data:
        statusupdate === 1
          ? { ...hiddenformData, status: 1 }
          : statusupdate === 2 && { ...hiddenformData, status: 0 },
    };

    await axios(config)
      .then((res) => {
        if (res.status === 200) {
          Store.dispatch(getAllLevy(res.data.levy));
          toast.success("Updated Successfully");
          setEdittoggle(true);
          handleCloseOverlay();
        }
      })
      .catch((err) => {
        setUploading(false);
        toast.error(err?.res?.data);
      });
  };

  const clearfields = () => {
    setFormData({
      levylabel: "",
      targetdemograph: "",
      amount: "",
      target_season: "",
      status: 0,
    });
  };

  const handleSelected = (incoming, e) => {
    setFormData({ ...formData, [incoming]: e });
  };

  var newdataset = paymentdata?.filter((filteredItem) => {
    if (searchdata === "") {
      return filteredItem;
    } else if (
      filteredItem.levy_label.toLowerCase().includes(searchdata) ||
      filteredItem.targetdemograph.toLowerCase().includes(searchdata)
    ) {
      return filteredItem;
    }
  });

  const handleClick = (row) => {
    setEdittoggle(false);
    setEditrow(row);
  };

  const handleToggle = (incoming) => {
    console.log("incoming :", incoming);
    setUpdaterow(incoming.id);
    if (incoming.status === 1) {
      setStatusupdate(1);
    } else if (incoming.status === 0) {
      setStatusupdate(2);
    }
  };

  const handleCloseOverlay = () => {
    setUploading(false);
    setStatusupdate(false);
  };

  return (
    <div className="flex w-full flex-col gap-3 min-h-screen min-w-[550px] relative">
      <div className="w-full bg-[white] h-[auto] flex flex-col gap-5 p-2">
        <div className="w-full font-[600] text-[16px] text-center items-center justify-center">
          <span className="underline underline-offset-4">CREATE LEVY</span>
        </div>

        <form
          onSubmit={edittoggle ? handleSubmitform : handleUpdateform}
          className="w-full flex flex-col justify-center items-center h-full gap-2"
        >
          <div className="w-full flex flex-wrap justify-center items-center h-full gap-2">
            <div className="flex flex-col w-[30%] min-w-[250px] grow gap-1 items-left">
              <div className="flex items-center gap-2 text-[13px] text-[gray]">
                <label>Levy Label</label>
                <p className="text-[red]">*</p>
              </div>

              <input
                type="text"
                placeholder="Levy Label"
                value={formData.levylabel}
                required
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    levylabel: e.target.value,
                  })
                }
                className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[200px] grow p-2 text-[13px] rounded-sm outline-none"
              />
            </div>

            <div className="flex flex-col w-[30%] min-w-[250px] grow gap-1 items-left">
              <div className="flex items-center gap-2 text-[13px] text-[gray]">
                <label>Amount</label>
                <p className="text-[red]">*</p>
              </div>

              <input
                type="text"
                placeholder="Levy Amount"
                value={formData.amount}
                required
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    amount: e.target.value,
                  })
                }
                className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[200px] grow p-2 text-[13px] rounded-sm outline-none"
              />
            </div>

            <div className="flex flex-col w-[30%] min-w-[200px] grow gap-1 items-left">
              <div className="flex items-center gap-2 text-[13px] text-[gray]">
                <label>Target Demographic</label>
                <p className="text-[red]">*</p>
              </div>

              <Singleselect
                dd_value={formData.targetdemograph}
                socialjs={TargetDemograph}
                selectedSocVal={(e) => handleSelected("targetdemograph", e)}
                placeholder="Target Demographic"
              />
            </div>

            <div className="flex flex-col w-[50%] min-w-[250px] grow gap-1 items-left">
              <div className="flex items-center gap-2 text-[13px] text-[gray]">
                <label>Target Season</label>
                <p className="text-[red]">*</p>
              </div>

              <Singleselect
                dd_value={formData.target_season}
                socialjs={TargetSeason}
                selectedSocVal={(e) => handleSelected("target_season", e)}
                placeholder="Target Season"
              />
            </div>
          </div>

          <div className="relative w-full max-w-[50%] h-[50px] flex items-center mt-4">
            <input
              disabled={loading ? true : false}
              type="submit"
              value={edittoggle ? "SUBMIT" : "UPDATE"}
              className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 cursor-pointer"
            />
            {loading && (
              <ReactLoading
                className="absolute left-[65%] top-[25%]"
                type="spin"
                color="white"
                height={"6%"}
                width={"6%"}
              />
            )}
          </div>
        </form>
      </div>

      <div className="w-full bg-[white] min-h-[70vh] flex flex-col gap-5 max-w-screen h-full overflow-x-auto relative">
        <table className="bnw-claims-table">
          <thead id="bnw-claims-table-head">
            <tr className="text-[13px] uppercase w-[80px]">
              <th scope="col" className="w-[30px]">
                #SN
              </th>
              <th scope="col">LEVY NAME</th>
              <th scope="col">AMOUNT</th>
              <th scope="col">TARGET DEMOGRAPH</th>
              <th scope="col">SEASON</th>
              <th scope="col">STATUS</th>
              <th scope="col">ACTION</th>
            </tr>
          </thead>

          <tbody>
            {newdataset
              ?.slice(pagesVisited, pagesVisited + itemsPerpage)
              .map((item, index) => (
                <tr key={index} className="text-[12px] font-normal">
                  <td data-label="#SN" className="bg-[#f9f9f9]">
                    {item.ID}
                  </td>

                  <td data-label="LEVY NAME">{item.levylabel}</td>

                  <td data-label="AMOUNT">{item.amount}</td>

                  <td data-label="TARGET DEMOGRAPH">{item.targetdemograph}</td>

                  <td data-label="SEASON">{item.target_season}</td>

                  <td data-label="STATUS">
                    <div
                      className={`flex text-[white] text-center items-center justify-center w-full h-[30px] ${
                        item.status === 1 ? "bg-[green]" : "bg-[orange]"
                      } `}
                    >
                      {item.status === 0 ? "In-active" : "Active"}
                    </div>
                  </td>

                  <td
                    data-label="ACTION"
                    className="flex gap-4 items-center justify-center"
                  >
                    <GrEdit
                      size={25}
                      onClick={(e) => handleClick(item.ID)}
                      className="rounded-md cursor-pointer hover:bg-[#ccc] p-1"
                    />
                    <Toggles
                      currentState={item.status}
                      index={item.ID}
                      setmode={handleToggle}
                    />{" "}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <div className="w-fit flex">
          <ButtonPagenate
            updatedPageNum={newPagenum}
            pageCount={pageCount}
            pageNumber={pageNumber}
            previousLabel="Prev"
            nextLabel="Next"
          />
        </div>
      </div>

      {statusupdate === 1 ? (
        <div className="w-full absolute h-full flex justify-center items-center overlaybackground">
          <div className="w-[50%] min-w-[280px] h-[250px] bg-[white] p-3 flex items-center flex-col gap-3">
            <div className="w-full flex items-center justify-end">
              <AiOutlineCloseCircle
                onClick={handleCloseOverlay}
                size={28}
                className="cursor-pointer"
              />
            </div>

            <span className="text-[15px] font-[800] underline underline-offset-4">
              ACTIVATION CONFIRMATION
            </span>

            <span className="text-[14px]">
              Are you sure you want to activate this Levy ?
            </span>

            <div className="flex justify-around items-center w-full mt-5">
              <div className="relative bg-[blue] h-fit flex items-center justify-center">
                <button
                  disabled={loading ? true : false}
                  onClick={handleActivate}
                  className="bg-[green] text-[white] flex justify-center items-center text-[14px] font-[500] w-[50px] p-2 cursor-pointer"
                >
                  YES
                </button>
                {uploading && (
                  <ReactLoading
                    className="absolute top-[0%] z-10"
                    type="spin"
                    color="white"
                    height={"70%"}
                    width={"70%"}
                  />
                )}
              </div>

              <div className="relative h-fit flex items-center justify-center">
                <button
                  onClick={handleCloseOverlay}
                  className="bg-[red] text-[white] flex justify-center items-center text-[14px] font-[500] w-[50px] p-2 cursor-pointer"
                >
                  NO
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        statusupdate === 2 && (
          <div className="w-full absolute h-full flex justify-center items-center overlaybackground">
            <div className="w-[50%] min-w-[280px] h-[250px] bg-[white] p-3 flex items-center flex-col gap-3">
              <div className="w-full flex items-center justify-end">
                <AiOutlineCloseCircle
                  onClick={handleCloseOverlay}
                  size={28}
                  className="cursor-pointer"
                />
              </div>

              <span className="text-[15px] font-[800] underline underline-offset-4">
                ACTIVATION CONFIRMATION
              </span>

              <span className="text-[14px]">
                Are you sure you want to make this Levy Inactive ?
              </span>

              <div className="flex justify-around items-center w-full mt-5">
                <div className="relative bg-[blue] h-fit flex items-center justify-center">
                  <button
                    disabled={loading ? true : false}
                    onClick={handleActivate}
                    className="bg-[green] text-[white] flex justify-center items-center text-[14px] font-[500] w-[50px] p-2 cursor-pointer"
                  >
                    YES
                  </button>
                  {uploading && (
                    <ReactLoading
                      className="absolute top-[0%] z-10"
                      type="spin"
                      color="white"
                      height={"70%"}
                      width={"70%"}
                    />
                  )}
                </div>

                <div className="relative h-fit flex items-center justify-center">
                  <button
                    onClick={handleCloseOverlay}
                    className="bg-[red] text-[white] flex justify-center items-center text-[14px] font-[500] w-[50px] p-2 cursor-pointer"
                  >
                    NO
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default CreatePayment;
