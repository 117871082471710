import React, { useState, useEffect } from "react";
import "./Sidebar.css";
import { AiOutlineDashboard } from "react-icons/ai";
import Togglemenu from "../Togglemenu/Togglemenu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Store, persistor, resetStore } from "../../Redux/store";
// import { authenticationStatus } from '../../Redux/Actions/pages';
// import { FaRegIdCard } from "react-icons/fa";
import { BsPeople } from "react-icons/bs";
import { GiTakeMyMoney } from "react-icons/gi";
import { LuBookCopy } from "react-icons/lu";
import { MdOutlineGroupAdd } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import { MdAddRoad } from "react-icons/md";
import { SlWallet } from "react-icons/sl";

// import { FcMoneyTransfer } from "react-icons/fc";
import { useSelector } from "react-redux";
import { sidebarToggleClick } from "../../Redux/Actions/buttonClick";
import { authenticationStatus } from "../../Redux/Actions/pages";

const Sidebar = ({ currentTab }) => {
  const { clickedtab } = useSelector((state) => state.buttonclick);
  console.log(currentTab);
  
  useEffect(() => {
    handleOnClick1(currentTab);
  }, []);

  const [link1, setLink1] = useState(true);
  const [link2, setLink2] = useState(false);
  const [link3, setLink3] = useState(false);
  const [link4, setLink4] = useState(false);
  const [link5, setLink5] = useState(false);
  const [link7, setLink7] = useState(false);
  const [toggle, setToggle] = useState(false);

  const handleOnClick1 = () => {
    if (currentTab === 1) {
      if (link1 === false) {
        setLink1(true);
        setLink2(false);
        setLink3(false);
        setLink4(false);
        setLink5(false);
        setLink7(false);
      }
    }

    if (currentTab === 2) {
      if (link2 === false) {
        setLink1(false);
        setLink2(true);
        setLink3(false);
        setLink4(false);
        setLink5(false);
        setLink7(false);
      }
    }

    if (currentTab === 3) {
      if (link3 === false) {
        setLink1(false);
        setLink2(false);
        setLink3(true);
        setLink4(false);
        setLink5(false);
        setLink7(false);
      }
    }

    if (currentTab === 4) {
      if (link4 === false) {
        setLink1(false);
        setLink2(false);
        setLink3(false);
        setLink4(true);
        setLink5(false);
        setLink7(false);
      }
    }

    if (currentTab === 5) {
      if (link5 === false) {
        setLink1(false);
        setLink2(false);
        setLink3(false);
        setLink4(false);
        setLink5(true);
        setLink7(false);
      }
    }

    if (currentTab === 7) {
      if (link7 === false) {
        setLink1(false);
        setLink2(false);
        setLink3(false);
        setLink4(false);
        setLink5(false);
        setLink7(true);
      }
    }
  };

  const ResetStore = () => {
    resetStore()
    Store.dispatch(
      authenticationStatus({
        isAuthenticated: true,
        userdata: {},
      })
    );
  };

  const handleOnClick = async (tab) => {
    console.log(tab);
    Store.dispatch(sidebarToggleClick(tab));
  };

  const toogleBar = () => {
    setToggle(!toggle);
  };

  return (
    <div
      className={
        toggle
          ? "navigation sticky 650px:absolute"
          : "navigation-active sticky 650px:absolute"
      }
    >
      <div className="toggle-contain h-[80px]" onClick={toogleBar}>
        <Togglemenu />
      </div>
      <ul>
        <li
          className={clickedtab === "tab_a" ? "list active" : "list"}
          name="list1"
          onClick={() => handleOnClick("tab_a")}
        >
          <Link
            to="/dashboard"
            className="a flex items-center"
            onClick={() => handleOnClick("tab_a")}
            name="list1"
          >
            <span className="icon">
              <AiOutlineDashboard id="icoons" />
            </span>
            <span className="title">Dashboard</span>
          </Link>
        </li>

        <li
          name="list2"
          className={clickedtab === "tab_b" ? "list active" : "list"}
          onClick={() => handleOnClick("tab_b")}
        >
          <Link
            to="/register"
            state={{ from: { id: 1 } }}
            className="a flex items-center"
            onClick={() => handleOnClick("tab_b")}
            name="list2"
          >
            <span className="icon">
              <BsPeople id="icoons" />
            </span>
            <span className="title">Occupants</span>
          </Link>
        </li>

        <li
          className={clickedtab === "tab_e" ? "list active" : "list"}
          name="list3"
          onClick={() => handleOnClick("tab_e")}
        >
          <Link
            to="/profile"
            className="a flex items-center"
            onClick={() => handleOnClick("tab_e")}
            name="list3"
          >
            <span className="icon">
              <MdOutlineGroupAdd id="icoons" />
            </span>
            <span className="title">Family Profile</span>
          </Link>
        </li>

        <li
          className={clickedtab === "tab_f" ? "list active" : "list"}
          name="list3"
          onClick={() => handleOnClick("tab_f")}
        >
          <Link
            to="/street"
            className="a flex items-center"
            onClick={() => handleOnClick("tab_f")}
            name="list3"
          >
            <span className="icon">
              <MdAddRoad id="icoons" />
            </span>
            <span className="title">Street</span>
          </Link>
        </li>

        <li
          className={clickedtab === "tab_c" ? "list active" : "list"}
          name="list3"
          onClick={() => handleOnClick("tab_c")}
        >
          <Link
            to="/records"
            className="a flex items-center"
            onClick={() => handleOnClick("tab_c")}
            name="list3"
          >
            <span className="icon">
              <LuBookCopy id="icoons" />
            </span>
            <span className="title">Records</span>
          </Link>
        </li>

        <li
          className={clickedtab === "tab_g" ? "list active" : "list"}
          name="list3"
          onClick={() => handleOnClick("tab_g")}
        >
          <Link
            to="/wallet"
            className="a flex items-center"
            onClick={() => handleOnClick("tab_g")}
            name="list3"
          >
            <span className="icon">
              <SlWallet id="icoons" />
            </span>
            <span className="title">Wallet</span>
          </Link>
        </li>

        <li
          className={clickedtab === "tab_d" ? "list active" : "list"}
          name="list3"
          onClick={() => handleOnClick("tab_d")}
        >
          <Link
            to="/payment"
            className="a flex items-center"
            onClick={() => handleOnClick("tab_d")}
            name="list3"
          >
            <span className="icon">
              <GiTakeMyMoney id="icoons" />
            </span>
            <span className="title">Levy</span>
          </Link>
        </li>

        <li className={link7 ? "list active" : "list"} onClick={ResetStore}>
          <Link
            to="/login"
            className="a flex items-center"
            onClick={ResetStore}
            name="list7"
          >
            <span className="icon">
              <FiLogOut id="icoons" />
            </span>
            <span className="title">Log Out</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
