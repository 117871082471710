import React, {useState, useEffect} from 'react'
import { IoChevronDown } from "react-icons/io5";
import './Singleselect.css'

const Singleselect = ({dd_value, selectedSocVal, placeholder, socialjs}) => {
    useEffect(() => {
        setSocialvalue(dd_value  === undefined ? '' : dd_value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [dd_value]);
    const [socialvalue, setSocialvalue] = useState(dd_value  === undefined ? '' : dd_value)
    const [social, setSocial] = useState(false)

    const handleSocialFocus = () =>{
        setSocial(!social)
    }
    
    const handleSocialClick = (e, incoming) =>{
        setSocialvalue(incoming.soc)
        setSocial(!social)
        selectedSocVal(incoming.soc)
        // residenceSelect(incoming.soc)
    }

    const handleValueChange = (e) =>{
        setSocial(true)
        setSocialvalue(e.target.value)
        // console.log(e.target.value)
    }
    
    // var SocialLists = socialjs.filter((filteredItem) => {
    //     if (filteredItem.name.toLowerCase().includes(socialvalue.toLowerCase())) {
    //       return filteredItem;
    //     }
    // });

    // console.log(SocialLists)

    const handleMouseLeave = () =>{
        setSocial(false)
    }

    var SocialLists = socialjs.filter((filteredItem) => {
        if (filteredItem.name.toLowerCase().includes(socialvalue.toLowerCase())) {
          return filteredItem;
        }
    });

  return (
    <div className='w-full flex relative'>
        <input value={socialvalue} onClick={handleSocialFocus} placeholder={placeholder} onChange={handleValueChange} className='placeholder-gray-500 placeholder-opacity-25 ... border w-full grow p-2 text-[13px] rounded-sm outline-none'/>
            <IoChevronDown id="chevron-down" onClick={handleSocialFocus}/>
            {social && <div onMouseLeave={handleMouseLeave} className='bnw-regionn-drpdwn'>{SocialLists.sort((a, b) => a.name > b.name ? 1 : -1).map((item, key) => (
                <span onClick={e => handleSocialClick(e, {soc: item.name, socid: item.ID})} key={key}>{item.name}</span>
            ))}
            </div>}
    </div>
  )
}

export default Singleselect