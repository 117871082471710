import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  currentab: 'landingpage',
  isAuthenticated: false,
  searchdata: '',
  userdata: {},
  tabdata: 1 
};

export const pageReducer = createReducer(initialState, {
  CurrentTab: (state, action) => {
    state.currentab = action.payload;
  },

  LoginResponse: (state, action) => {
    console.log(action.payload)
    state.isAuthenticated = action.payload.isAuthenticated
    state.userdata = action.payload.userdata
  },

  SearchResponse: (state, action) => {
    state.searchdata = action.payload
  },

  SideTab: (state, action) =>{
    state.tabdata = action.payload
  }
});
