import React from "react";

const PaymentHistories = ({ name, description, transid, amount, status }) => {
  return (
    <div className="w-full flex items-center justify-between gap-2">
      <div className="w-[30%] flex flex-col">
        <span className="font-[500] text-[#333] text-[12px]">{name}</span>

        <span
          className={`p-1 px-2 rounded-[5px] text-[white] text-[11px] ${
            status === "Dr" ? "bg-[#C60C30]" : "bg-[green]"
          } w-fit`}
        >
          {description}
        </span>
      </div>

      <div className="w-[30%] flex flex-col items-end">
        <span className="font-[600] text-[#333] text-[13px]">
          {status === "Dr" && "-"} {amount} <b>NGN</b>
        </span>

        <span className=" rounded-[5px] text-[#222] text-[9px] w-fit">
          Tran. ID {transid}
        </span>
      </div>
    </div>
  );
};

export default PaymentHistories;
