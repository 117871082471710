import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  mode: 0 
};

export const togglebuttonsReducer = createReducer(initialState, {
  ToggleButton: (state, action) => {
    state.mode = action.payload;
  }
});
