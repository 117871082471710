import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  occupant: [],
  street: [],
  house: [],
  asset: [],
  levy: [],
  levyhistory: []
};

export const responseReducer = createReducer(initialState, {
  OccupantList: (state, action) => {
    state.occupant = action.payload;
  },

  StreetList: (state, action) => {
    state.street = action.payload;
  },

  HouseList: (state, action) => {
    state.house = action.payload;
  },

  AssetList: (state, action) => {
    state.asset = action.payload;
  },

  LevyList: (state, action) => {
    state.levy = action.payload;
  },

  LevyHistory: (state, action) => {
    state.levyhistory = action.payload;
  },
});

