import React, { useState, useEffect } from "react";
import { Store } from "../../Redux/store";
import {
  clickedTab,
  currentPage,
  searchFilter,
} from "../../Redux/Actions/pages";
import person from "../../assets/team.png";
import mylevy from "../../assets/naira.png";
import family from "../../assets/family.png";
import assetImg from "../../assets/asset.png";
import allstreet from "../../assets/motorway.png";
import buildings from "../../assets/skyscraper.png";

import { useCookies } from "react-cookie";
import { setAuthToken } from "../../utils/interceptor";
import { server } from "../../server";
import axios from "axios";
import {
  getAllAssets,
  getAllHouses,
  getAllLevy,
  getAllOccupants,
  getAllStreet,
  getPaymentHistory,
} from "../../Redux/Actions/dataResponse";
import { useSelector } from "react-redux";

import "../../index.css";

const Dashboard = () => {
  const [cookies] = useCookies(["Token"]);
  const { userdata } = useSelector((state) => state.users);
  const { tabdata } = useSelector((state) => state.tab);
  const { buttonClicks } = useSelector((state) => state.buttonclick);
  const { occupant, street, house, asset, levy } = useSelector(
    (state) => state.datareponse
  );

  const [active, setActive] = useState(userdata.role.includes("Admin") ? 1 : 3);

  // console.log(userdata);
  // setAuthToken(cookies.Token);

  useEffect(() => {
    // 👇️ scroll to top on page load
    Store.dispatch(currentPage("authenticated"));
    Makerolecall();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleTab(tabdata);
    setActive(userdata.role.includes("Admin") ? 1 : 3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabdata]);

  const Makerolecall = async () => {
    try {
      var config = {
        method: "GET",
        url: `${server}/fetchalldata`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + cookies.Token,
        },
      };

      console.log(config);

      await axios(config).then((response) => {
        console.log(response.data.data);
        if (response.data.success === true) {
          Store.dispatch(getAllOccupants(response.data.data.occupants));
          Store.dispatch(getAllStreet(response.data.data.street));
          Store.dispatch(getAllHouses(response.data.data.houses));
          Store.dispatch(getAllAssets(response.data.data.assets));
          Store.dispatch(getAllLevy(response.data.data.levy));
          Store.dispatch(getPaymentHistory(response.data.data.paymenthistory));
        }
      });
    } catch (e) {
      // setLoading(false);
      console.log("Obaloluwa", e);
    }
  };

  const handleTab = (tab) => {
    setActive(tab);
  };

  const allOccupants = useSelector((state) => state.datareponse);

  const relatedOccupant = allOccupants?.occupant?.filter(
    (occupantPerson) => occupantPerson.family_id === userdata.family_id
  );

  const personalAssets = asset?.filter(
    (myassets) => myassets.family_id === userdata.family_id
  );

  const maleDependant = relatedOccupant?.filter(
    (occupantPerson) => occupantPerson.gender !== "Female"
  );

  const primary = allOccupants?.occupant?.filter(
    (occupantPerson) => occupantPerson.occupant_type !== "Dependant"
  );

  const handleClickedTab = (props) => {
    Store.dispatch(clickedTab(props));
  };

  return (
    <div className="m-3 bg-[#f9f9f9] w-full min-h-screen pr-6 min-w-[500px] flex flex-col gap-2 ">
      <div className="flex justify-center items-center gap-3 w-full flex-wrap h-full p-2">
        {userdata.role.includes("Admin") && (
          <div
            className="w-[350px] p-3 gap-6 min-w-[200px] grow h-[200px] bg-[#fff] rounded-md flex flex-col justify-start items-center cursor-pointer relative overflow-hidden shadow-sm hover:shadow-md"
            onClick={(e) => handleClickedTab(4)}
          >
            <div className="w-full flex justify-around items-center">
              <div className="flex items-center gap-2">
                <div className="p-1 rounded-full bg-[red]" />
                <span className="text-[11px]">Primary Occupant</span>
              </div>

              <div className="flex items-center gap-2">
                <div className="p-1 rounded-full bg-[green]" />
                <span className="text-[11px]">Dependant</span>
              </div>
            </div>

            <div className="flex flex-col w-full justify-start px-9">
              <span className="text-[16px] font-[400] text-[#e85000]">
                OCCUPANTS
              </span>
              <h1 className="text-[35px] font-[600] text-[#e85000]">
                {occupant.length < 10 ? "0" + occupant.length : occupant.length}
              </h1>
            </div>

            <div className="w-full flex flex-col justify-around items-start">
              <div className="flex items-center gap-2">
                <div className="p-1 w-[20px] bg-[red]" />
                <span className="text-[11px]">{primary.length}</span>
              </div>

              <div className="flex items-center gap-2">
                <div className="p-1 w-[20px] bg-[green]" />
                <span className="text-[11px]">
                  {occupant.length - primary.length}
                </span>
              </div>
            </div>

            <div className="w-[400px] h-[400px] right-[-260px] bottom-[-260px] rounded-full absolute background_color" />
            <img
              src={person}
              className="absolute w-[65px] h-[65px] right-[10px] bottom-[0px]"
              alt="myimgs"
            />
          </div>
        )}

        {userdata.role.includes("Admin") && (
          <div
            className="w-[200px] p-3 gap-6 min-w-[200px] grow h-[200px] bg-[#fff] rounded-md flex flex-col justify-start items-center cursor-pointer relative overflow-hidden shadow-sm hover:shadow-md"
            onClick={(e) => handleClickedTab(4)}
          >
            <div className="w-full flex justify-around items-center"></div>

            <div className="flex flex-col w-full justify-start px-9">
              <span className="text-[16px] font-[400] text-[#e85000]">
                STREETS
              </span>
              <h1 className="text-[35px] font-[600] text-[#e85000]">
                {street.length < 10 ? "0" + street.length : street.length}
              </h1>
            </div>

            <div className="w-full flex flex-col justify-around items-start"></div>

            <img
              src={allstreet}
              className="absolute w-[50px] h-[50px] right-[10px] bottom-[10px]"
              alt="myimgs"
            />

            <div className="w-[400px] h-[400px] right-[-260px] bottom-[-260px] rounded-full absolute background_color_1" />
          </div>
        )}

        {userdata.role.includes("Admin") && (
          <div
            className="w-[200px] p-3 gap-6 min-w-[200px] grow h-[200px] bg-[#fff] rounded-md flex flex-col justify-start items-center cursor-pointer relative overflow-hidden shadow-sm hover:shadow-md"
            onClick={(e) => handleClickedTab(4)}
          >
            <div className="w-full flex justify-around items-center"></div>

            <div className="flex flex-col w-full justify-start px-9">
              <span className="text-[16px] font-[400] text-[#e85000]">
                BUILDINGS
              </span>
              <h1 className="text-[35px] font-[600] text-[#e85000]">
                {house.length < 10 ? "0" + house.length : house.length}
              </h1>
            </div>

            <div className="w-full flex flex-col justify-around items-start"></div>

            <img
              src={buildings}
              className="absolute w-[50px] h-[50px] right-[10px] bottom-[10px]"
              alt="myimgs"
            />

            <div className="w-[400px] h-[400px] right-[-260px] bottom-[-260px] rounded-full absolute background_color_1" />
          </div>
        )}

        <div
          className="w-[200px] p-3 gap-6 min-w-[200px] grow h-[200px] bg-[#fff] rounded-md flex flex-col justify-start items-center cursor-pointer relative overflow-hidden shadow-sm hover:shadow-md"
          onClick={(e) => handleClickedTab(4)}
        >
          <div className="w-full flex justify-around items-center">
            <div className="flex items-center gap-2">
              <div className="p-1 rounded-full bg-[red]" />
              <span className="text-[11px]">Male</span>
            </div>

            <div className="flex items-center gap-2">
              <div className="p-1 rounded-full bg-[green]" />
              <span className="text-[11px]">Female</span>
            </div>
          </div>

          <div className="flex flex-col w-full justify-start px-9">
            <span className="text-[16px] font-[400] text-[maroon]">
              DEPENDANTS
            </span>
            <h1 className="text-[35px] font-[600] text-[maroon]">
              {relatedOccupant.length < 10
                ? "0" + relatedOccupant.length
                : relatedOccupant.length}
            </h1>
          </div>

          <div className="w-full flex flex-col justify-around items-start">
            <div className="flex items-center gap-2">
              <div className="p-1 w-[20px] bg-[red]" />
              <span className="text-[11px]">{maleDependant.length}</span>
            </div>

            <div className="flex items-center gap-2">
              <div className="p-1 w-[20px] bg-[green]" />
              <span className="text-[11px]">
                {relatedOccupant.length - maleDependant.length}
              </span>
            </div>
          </div>

          <img
            src={family}
            className="absolute w-[50px] h-[50px] right-[10px] bottom-[10px]"
            alt="myimgs"
          />

          <div className="w-[400px] h-[400px] right-[-260px] bottom-[-260px] rounded-full absolute background_color_1" />
        </div>

        <div
          className="w-[200px] min-w-[200px] grow h-[200px] bg-[#fff] rounded-md flex  justify-center items-center cursor-pointer relative overflow-hidden shadow-sm hover:shadow-md"
          onClick={(e) => handleClickedTab(5)}
        >
          <div className="flex flex-col w-full justify-start px-9">
            <span className="text-[16px] font-[400] text-[brown]">
              UNPAID LEVY
            </span>
            <h1 className="text-[35px] font-[600] text-[brown]">
              {relatedOccupant.length < 10
                ? "0" + relatedOccupant.length
                : relatedOccupant.length}
            </h1>
          </div>

          <div className="w-[400px] h-[400px] right-[-260px] bottom-[-260px] rounded-full absolute background_color_2" />
          <img
            src={mylevy}
            className="absolute w-[50px] h-[50px] right-[10px] bottom-[10px]"
            alt="myimgs"
          />
        </div>

        {/* <div
          className="w-[200px] min-w-[200px] grow h-[200px] bg-[#fff] rounded-md flex  justify-center items-center cursor-pointer relative overflow-hidden shadow-sm hover:shadow-md"
          onClick={(e) => handleClickedTab(5)}
        >
          <div className="flex flex-col w-full justify-start px-9">
            <span className="text-[16px] font-[400] text-[brown]">
              UNPAID LEVY
            </span>
            <h1 className="text-[35px] font-[600] text-[brown]">
              {relatedOccupant.length < 10
                ? "0" + relatedOccupant.length
                : relatedOccupant.length}
            </h1>
          </div>

          <div className="w-[400px] h-[400px] right-[-260px] bottom-[-260px] rounded-full absolute background_color_2" />
          <img
            src={levy}
            className="absolute w-[50px] h-[50px] right-[10px] bottom-[10px]"
            alt="myimgs"
          />
        </div> */}

        {userdata.role.includes("Admin") && (
          <div
            className="w-[200px] min-w-[200px] max-w-[500px] grow h-[200px] bg-[#fff] rounded-md flex flex-col p-3 gap-6 justify-start items-center cursor-pointer relative overflow-hidden shadow-sm hover:shadow-md"
            onClick={(e) => handleClickedTab(3)}
          >
            <div className="w-full flex justify-around items-center">
              <div className="flex items-center gap-2">
                <div className="p-1 rounded-full bg-[blue]" />
                <span className="text-[11px]">Personal Assets :</span>
                <span className="text-[11px] font-[500]">
                  {personalAssets?.length < 10
                    ? "0" + personalAssets?.length
                    : personalAssets?.length}
                </span>
              </div>
            </div>

            <div className="flex flex-col w-full justify-start px-9">
              <span className="text-[16px] font-[400] text-[#3339]">
                ASSETS
              </span>
              <h1 className="text-[35px] font-[600] text-[#3339]">
                {asset?.length < 10 ? "0" + asset?.length : asset?.length}
              </h1>
            </div>

            <div className="w-[400px] h-[400px] right-[-260px] bottom-[-260px] rounded-full absolute background_color_3" />
            <img
              src={assetImg}
              className="absolute w-[50px] h-[50px] right-[10px] bottom-[10px]"
              alt="myimgs"
            />
          </div>
        )}

        {userdata.role.includes("User") && (
          <div
            className="w-[200px] min-w-[200px] max-w-[500px] grow h-[200px] bg-[#fff] rounded-md flex flex-col gap-3 justify-center items-center cursor-pointer relative overflow-hidden shadow-sm hover:shadow-md"
            onClick={(e) => handleClickedTab(3)}
          >
            <div className="flex flex-col w-full justify-start px-9">
              <span className="text-[16px] font-[400] text-[#3339]">
                PERSONAL ASSETS
              </span>
              <h1 className="text-[35px] font-[600] text-[#3339]">
                {personalAssets?.length < 10
                  ? "0" + personalAssets?.length
                  : personalAssets?.length}
              </h1>
            </div>

            <div className="w-[400px] h-[400px] right-[-260px] bottom-[-260px] rounded-full absolute background_color_3" />
            <img
              src={assetImg}
              className="absolute w-[50px] h-[50px] right-[10px] bottom-[10px]"
              alt="myimgs"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
