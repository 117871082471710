export const getAllOccupants = (occupantList) => ({
    type: "OccupantList",
    payload: occupantList
})

export const getAllStreet = (streetList) => ({
    type: "StreetList",
    payload: streetList
})

export const getAllHouses = (houseList) => ({
    type: "HouseList",
    payload: houseList
})

export const getAllAssets = (assetList) => ({
    type: "AssetList",
    payload: assetList
})

export const getAllLevy = (levyList) => ({
    type: "LevyList",
    payload: levyList
})

export const getPaymentHistory = (paymenthistory) => ({
    type: "LevyHistory",
    payload: paymenthistory
})