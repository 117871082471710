import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoIosCard } from "react-icons/io";
import "../../Pages/styles.css";
import { FaCalendarWeek, FaInfoCircle } from "react-icons/fa";
import { IoWarning } from "react-icons/io5";
import atm_card_icon from "../../assets/icons/atm-card.png";

const Addcard = ({ captureAddButtonClick }) => {
  const handleClose = () => {
    captureAddButtonClick(0);
  };

  const [focusedField, setFocusedField] = useState(null);

  const handleFocus = (fieldName) => {
    setFocusedField(fieldName);
  };

  const handleSubmit = () => {};

  return (
    <div className="w-full h-full absolute bg-[black] flex justify-center items-center overlaybackground">
      <div className="min-w-[300px] w-[60%] h-fit min-h-[300px] bg-[white] flex flex-col p-3">
        <div className="w-flex relative flex justify-end items-center">
          <AiOutlineCloseCircle
            fontSize={30}
            className="cursor-pointer"
            onClick={handleClose}
          />
        </div>

        <div className="w-full flex justify-center items-center">
          <span className="text-[16px] font-[800] mb-4">ADD NEW CARD</span>
        </div>

        <div className="w-full h-full flex items-center gap-1 card1200px:flex-col">
          <div className="w-[40%] h-full max-w-[400px] flex justify-start items-center px-2 card1200px:w-full">
            <div className="w-full min-w-[220px] flex justify-center items-center h-[150px]">
              <img
                alt=""
                src={atm_card_icon}
                className="w-[60%] min-w-[130px] max-w-[150px]"
              />
            </div>
          </div>

          <form
            onSubmit={handleSubmit}
            className="w-[60%] max-w-[500px] min-w-[300px] h-full flex flex-col justify-start items-center p-2 gap-2 card1200px:w-full"
          >
            <div className="relative w-full">
              <input
                className={`w-full p-2 px-3 text-[13px] outline-none border p-2 outline-none focus:border-blue-500 ${
                  focusedField === "field1"
                    ? "border-blue-500"
                    : "border-gray-300"
                } rounded-[5px]`}
                placeholder="0000  0000  0000  0000"
                onFocus={() => handleFocus("field1")}
                required
              />
              <IoIosCard
                fontSize={18}
                color={focusedField === "field1" ? "#5417a3" : "#ccc"}
                className="absolute right-5 top-[25%]"
              />
            </div>

            <div className="w-full gap-2 flex items-center flex-wrap">
              <div className="relative grow min-w-[50px] w-[40%]">
                <input
                  className={`w-full p-2 px-3 text-[13px] outline-none border p-2 outline-none focus:border-blue-500 ${
                    focusedField === "field2"
                      ? "border-blue-500"
                      : "border-gray-300"
                  } rounded-[5px] `}
                  placeholder="MM/YY"
                  onFocus={() => handleFocus("field2")}
                  required
                />
                <FaCalendarWeek
                  fontSize={18}
                  color={focusedField === "field2" ? "#5417a3" : "#ccc"}
                  className="absolute right-5 top-[25%]"
                />
              </div>

              <div className="relative grow min-w-[50px] w-[40%]">
                <input
                  className={`w-full p-2 px-3 text-[13px] outline-none border p-2 outline-none focus:border-blue-500 ${
                    focusedField === "field3"
                      ? "border-blue-500"
                      : "border-gray-300"
                  } rounded-[5px] `}
                  placeholder="CVC/CCV"
                  onFocus={() => handleFocus("field3")}
                  required
                />
                <FaInfoCircle
                  fontSize={18}
                  color={focusedField === "field3" ? "#5417a3" : "#ccc"}
                  className="absolute right-5 top-[25%]"
                />
              </div>
            </div>

            <div className="relative w-full">
              <input
                className={`w-full p-2 px-3 text-[13px] outline-none border p-2 outline-none focus:border-blue-500 ${
                  focusedField === "field4"
                    ? "border-blue-500"
                    : "border-gray-300"
                } rounded-[5px]`}
                placeholder="Enter cardholder's fullname"
                onFocus={() => handleFocus("field4")}
                required
              />
            </div>

            <div className="w-full flex items-center gap-2">
              <IoWarning fontSize={14} color="orange" />
              <span className="text-[10px] text-[orange]">
                Do not Panic! Your card details will be cryptographically stored
              </span>
            </div>

            <input
              type="submit"
              value="Add Card"
              className="p-2 rounded-3 bg-[#318CE7] w-full rounded-[5px] mt-3 cursor-pointer text-[white] text-[13px]"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Addcard;
