import React from 'react'
import './contactus.css'
import Contact from '../../Components/contactus'

const Contactus = () => {
  return (
    <div className='min-h-screen w-full min-w-[500px]'>
        <div className="w-full flex flex-col gap-9 p-5">
            <div className="row">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.341361850382!2d3.3375809144497692!3d6.604433624050279!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b93cd55a1b743%3A0xcf38ecb90a7a938c!2sSabre%20Travel%20Solutions%2C%20Central%20%26%20West%20Africa!5e0!3m2!1sen!2sng!4v1680790223439!5m2!1sen!2sng"
                    width="100%"
                    height="500"
                    style={{border:0}}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    title="map"
                ></iframe>
            </div>

            <div className="row mt-6">
                <Contact/>
            </div>
            
        </div>
    </div>
  )
}

export default Contactus