import React, { useState, useEffect } from "react";
import Hero from "../Components/Hero";
import Slider from "react-styled-carousel";
import chairman from "../assets/committee/chairman.jpg";
import "./pages.css";
import "./styles.css";
import Portfolio from "../Components/portfolio";
import Team from "../Components/Team";
import { authenticationStatus, currentPage } from "../Redux/Actions/pages";
import { Store } from "../Redux/store";

const Landingpage = () => {
  const [overlay, setOverlay] = useState(false);

  useEffect(() => {
    // 👇️ scroll to top on page load
    Store.dispatch(currentPage("landingpage"));
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    Store.dispatch(
      authenticationStatus({
        isAuthenticated: false,
        userdata: {},
      })
    );
  }, []);

  const responsive = [
    { breakPoint: 1280, cardsToShow: 1 }, // this will be applied if screen size is greater than 1280px. cardsToShow will become 4.
    { breakPoint: 760, cardsToShow: 1 },
  ];

  const data = [
    {
      appType: "OmotoyeCDA",
      tagLine:
        "Welcome to Omotoye Estate, your perfect destination to build and rent a property ",
      description: "We welcome our partners and neighbors with open arms",
      mainActionText: "Playstore",
      extraActionText: "App Store",
      img: "bg-estlayout_1",
    },
    {
      appType: "OmotoyeCDA",
      tagLine:
        "Welcome to Omotoye Estate, your perfect destination to build and rent a property ",
      description:
        "Purchase your property with us and sleep with your two eyes closed",
      mainActionText: "Playstore",
      extraActionText: "App Store",
      img: "bg-estlayout_2",
    },
    {
      appType: "OmotoyeCDA",
      tagLine:
        "Welcome to Omotoye Estate, your perfect destination to build and rent a property ",
      description: "Security of Lives and property is guaranteed here",
      mainActionText: "Playstore",
      extraActionText: "App Store",
      img: "bg-estlayout_4",
    },
  ];

  const handleOverlay = () => {
    setOverlay(!overlay);
  };

  return (
    <div className="w-full min-h-screen min-w-[500px] flex flex-col relative">
      <section id="home">
        <Slider
          hideArrowsOnNoSlides={true}
          showDots={false}
          pauseOnMouseOver={true}
          autoSlide={5000}
          showArrows={false}
          padding={"0px"}
          responsive={responsive}
          cardsToShow={1}
          // LeftArrow={<FaChevronCircleLeft id="carousel-icons-left"/>}
          // RightArrow={<FaChevronCircleRight id="carousel-icons-right" />}
          infinite
        >
          {data.map((items, index) => (
            <Hero
              key={index}
              appType={items.appType}
              tagLine={items.tagLine}
              description={items.description}
              mainActionText={items.mainActionText}
              extraActionText={items.extraActionText}
              img={items.img}
            />
          ))}
        </Slider>
      </section>

      <section id="about" className="flex flex-col p-9">
        <div className="w-full flex flex-col pt-5">
          <div className="section-title">
            <h2>Chariman's Address</h2>
            <p>Mrs. Shofola Adekemi Ladi</p>
          </div>

          <div className="w-full flex items-center px-[10px] justify-around gap-2 h-[300px] 650px:flex-col 650px:h-fit 650px:gap-8">
            <div className="w-[300px] h-[300px] bg-[green] ring-offset-1 ring-1 overflow-hidden rounded-full 800px:w-[300px] 800px:h-[300px]">
              <img src={chairman} alt="chairman's pix" />
            </div>

            <div className="max-w-[60%] h-full flex items-center 650px:max-w-full">
              <span className=" px-4 text-[15px] italic ">
                <b>Ladies and gentlemen, </b> <br /> <br /> As the Chairman of
                our wonderful residential estate, I stand before you today
                filled with pride and optimism for the future of our community.
                It is a privilege to address you all and share my vision for the
                progress and development of our estate. <br />
                <br /> First and foremost, I would like to express my heartfelt
                gratitude to each and every one of you for choosing to be a part
                of this thriving community. Our estate is more than just a
                collection of houses; it is a place we call home, where we build
                friendships, create memories, and form a strong sense of
                belonging. Together, we have the power to shape our community
                into a truly remarkable place to live.
                <br /> <br /> Cooperation is the key to unlocking the full
                potential of our estate. We are a diverse group of individuals
                with unique talents and perspectives, and by working together,
                we can achieve extraordinary things. I urge each resident to
                actively participate in the decision-making processes, as well
                as contribute their time, skills, and ideas towards the
                betterment of our community... <br />
                <span
                  className="font-500 rounded-md cursor-pointer text-[12px] hover:underline underline-offset-2"
                  onClick={handleOverlay}
                >
                  Read More ...
                </span>
              </span>
            </div>
          </div>
        </div>
      </section>

      <section id="gallery" className="flex flex-col p-9">
        <Portfolio />
      </section>

      <section id="committee" className="flex flex-col p-9">
        <Team />
      </section>

      {overlay && (
        <div
          className="bg-black-rgba w-full h-full absolute flex justify-center items-center cursor-pointer pt-[80px] top-0"
          onClick={handleOverlay}
        >
          <div className="w-full h-full">
            <section id="about" className="flex flex-col p-9 h-full">
              <div className="w-full flex flex-col pt-5">
                <div className="section-title">
                  <h2>Chariman's Address</h2>
                  <p>Mrs. Shofola Adekemi Ladi</p>
                </div>

                <div className="w-full flex items-center px-[10px] justify-around gap-2  900px:flex-col 900px:h-fit 900px:gap-8">
                  <div className="w-[300px] h-[300px] sticky top-0 ring-offset-1 ring-1 overflow-hidden rounded-full 800px:w-[300px] 800px:h-[300px]">
                    <img src={chairman} alt="chairman's pix" />
                  </div>

                  <div className="max-w-[60%] min-h-[100vh]  900px:max-w-full p-4 text-[15px] italic pt-[50px]">
                    <b>Ladies and gentlemen, </b> <br /> <br /> As the Chairman
                    of our wonderful residential estate, I stand before you
                    today filled with pride and optimism for the future of our
                    community. It is a privilege to address you all and share my
                    vision for the progress and development of our estate.{" "}
                    <br />
                    <br /> First and foremost, I would like to express my
                    heartfelt gratitude to each and every one of you for
                    choosing to be a part of this thriving community. Our estate
                    is more than just a collection of houses; it is a place we
                    call home, where we build friendships, create memories, and
                    form a strong sense of belonging. Together, we have the
                    power to shape our community into a truly remarkable place
                    to live.
                    <br /> <br /> Cooperation is the key to unlocking the full
                    potential of our estate. We are a diverse group of
                    individuals with unique talents and perspectives, and by
                    working together, we can achieve extraordinary things. I
                    urge each resident to actively participate in the
                    decision-making processes, as well as contribute their time,
                    skills, and ideas towards the betterment of our community
                    <br /> <br /> To foster a sense of unity and camaraderie, we
                    will be organizing regular community events and social
                    gatherings. These occasions will provide us with
                    opportunities to connect, share experiences, and strengthen
                    the bonds that tie us together. Let us embrace these events
                    wholeheartedly and make them enjoyable and inclusive for
                    everyone. <br /> <br />
                    Furthermore, I believe it is crucial for us to prioritize
                    the maintenance and improvement of our estate's
                    infrastructure. Our roads, parks, and communal facilities
                    need to be well-maintained and upgraded when necessary. I
                    assure you that we will diligently work towards ensuring the
                    highest standards of cleanliness, safety, and aesthetics
                    within our estate. <br /> <br />
                    In the spirit of progress, I encourage all residents to
                    engage in sustainable practices. Let us embrace
                    environmentally friendly initiatives such as waste
                    reduction, recycling, and energy conservation. By doing so,
                    we can contribute to the preservation of our environment and
                    leave a positive impact on future generations. <br /> <br />
                    Communication is the lifeblood of any successful community.
                    To facilitate effective communication among residents, we
                    are in the process of developing an online platform where
                    information, updates, and announcements will be readily
                    accessible to all. This platform will serve as a hub for
                    discussions, feedback, and the exchange of ideas. Your
                    inputs and suggestions are invaluable, and I encourage you
                    to make use of this platform to voice your opinions and
                    concerns. <br /> <br />
                    Lastly, I want to emphasize that our estate is more than
                    just a physical space; it is a reflection of the collective
                    spirit and values of its residents. Let us foster a culture
                    of respect, understanding, and inclusivity. By embracing
                    diversity and treating one another with kindness and
                    compassion, we can create a harmonious and welcoming
                    environment for all. <br /> <br />
                    In conclusion, our residential estate holds immense
                    potential for growth and progress. It is in our hands to
                    shape its destiny. Let us stand united, work together, and
                    strive for excellence. By cooperating and collaborating, we
                    can build a vibrant and prosperous community that we can all
                    be proud to call home. <br /> <br />
                    Thank you for your attention, and I look forward to
                    embarking on this journey of progress with each and every
                    one of you. <br />
                    <br />
                    Best regards
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </div>
  );
};

export default Landingpage;
