const getCurrentFormattedDate = () => {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const currentDate = new Date();

  const dayOfWeek = daysOfWeek[currentDate.getDay()];
  const dayOfMonth = currentDate.getDate();
  const month = months[currentDate.getMonth()];
  const year = currentDate.getFullYear();

  // Get the day suffix (e.g., "st", "nd", "rd", "th")
  const daySuffix =
    dayOfMonth % 10 === 1 && dayOfMonth !== 11
      ? "st"
      : dayOfMonth % 10 === 2 && dayOfMonth !== 12
      ? "nd"
      : dayOfMonth % 10 === 3 && dayOfMonth !== 13
      ? "rd"
      : "th";

  const formattedDate = `${dayOfWeek}, ${dayOfMonth}${daySuffix} ${month}, ${year}`;

  return formattedDate;
};

export default getCurrentFormattedDate;
