import React, { useState } from "react";
import ButtonPagenate from "../ButtonPagenate/ButtonPagenate";
import { useSelector } from "react-redux";
import AddStreet from "../AddStreet/AddStreet";

const StreetList = ({ captureAddButtonClick }) => {
  const { street } = useSelector((state) => state.datareponse);
  const { searchdata } = useSelector((state) => state.searchfilter);

  console.log(street);

  const [pageNumber, setPageNumber] = useState(0);

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const itemsPerpage = 20; //Number of items to be displayed per page
  const pageCount = Math.ceil(street?.length / itemsPerpage);
  // console.log(occupant.length)
  // console.log(pageCount)
  const pagesVisited = pageNumber * itemsPerpage;

  var newdataset = street?.filter((filteredItem) => {
    if (searchdata === "") {
      return filteredItem;
    } else if (filteredItem.name.toLowerCase().includes(searchdata)) {
      return filteredItem;
    }
  });

  const handleAddButtn = () => {
    captureAddButtonClick(1);
  };

  return (
    <div className="flex flex-col gap-5 max-w-screen h-full overflow-x-auto relative">
      <table className="bnw-claims-table">
        <thead id="bnw-claims-table-head">
          <tr className="text-[13px] uppercase">
            <th scope="col">#SN</th>
            <th scope="col">Street Name</th>
          </tr>
        </thead>
        <tbody>
          {newdataset
            .slice(pagesVisited, pagesVisited + itemsPerpage)
            .map((item, key) => (
              <tr key={key} className="text-[12px] font-normal">
                <td data-label="#SN">{item.ID}</td>
                <td data-label="Street Name">{item.name}</td>
              </tr>
            ))}
        </tbody>
      </table>

      <div className="w-fit flex">
        <ButtonPagenate
          updatedPageNum={newPagenum}
          pageCount={pageCount}
          pageNumber={pageNumber}
          previousLabel="Prev"
          nextLabel="Next"
        />

        <button id="addbitn" onClick={handleAddButtn}>
          Add
        </button>
      </div>
    </div>
  );
};

export default StreetList;
