import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { enrollFormReducer } from "./Reducers/Enrollform";
import { buttonClickReducer } from "./Reducers/buttonClick";
import { pageReducer } from "./Reducers/pages";
import { responseReducer } from "./Reducers/dataResponse";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import { togglebuttonsReducer } from "./Reducers/togglebuttonsReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "buttonclick", "users", "datareponse"],
};

const rootReducer = combineReducers({
  user: enrollFormReducer,
  buttonclick: buttonClickReducer,
  users: pageReducer,
  datareponse: responseReducer,
  searchfilter: pageReducer,
  tab: pageReducer,
  modeestate: togglebuttonsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const Store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(Store);

export const resetStore = async () => {
  await persistor.purge(); // Clears the persisted state
};
