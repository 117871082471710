export const genderJSON = [
    {
        ID: 1,
        name: 'Male'
    },
    {
        ID: 2,
        name: 'Female'
    }
]

export const titleJSON = [
    {
        ID: 1,
        name: 'Mr.'
    },
    {
        ID: 2,
        name: 'Mrs.'
    },
    {
        ID: 3,
        name: 'Miss.'
    },
    {
        ID: 4,
        name: 'Engr.'
    },
    {
        ID: 5,
        name: 'Pstr'
    },
    {
        ID: 6,
        name: 'Rev.'
    },
    {
        ID: 7,
        name: 'Apst.'
    },
    {
        ID: 8,
        name: 'Brst.'
    },
    {
        ID: 9,
        name: 'Dr.'
    },
    {
        ID: 10,
        name: 'Alhj.'
    },
    {
        ID: 11,
        name: 'Malam'
    }
]
