export const maritalStatusJSON = [
    {
        ID: 1,
        name: 'Single'
    },
    {
        ID: 2,
        name: 'Married'
    },
    {
        ID: 3,
        name: 'Divorce'
    },
    {
        ID: 4,
        name: 'Widow'
    },
    {
        ID: 5,
        name: 'Single Parent'
    },
    {
        ID: 6,
        name: 'Complicated'
    },
    
]


export const livingStatusJSON = [
    {
        ID: 1,
        name: 'Alive'
    },
    {
        ID: 2,
        name: 'Deceased'
    }    
]

export const rolesJSON = [
    {
        ID: 1,
        name: 'User'
    }  
]

export const superRolesJSON = [
    {
        ID: 1,
        name: 'Admin'
    },
    {
        ID: 2,
        name: 'User'
    }    
]

export const relationshipStatus = [
    {
        ID: 1,
        name: 'Parent'
    },
    {
        ID: 2,
        name: 'Spouse'
    },
    {
        ID: 3,
        name: 'Siblings'
    },
    {
        ID: 4,
        name: 'Children'
    },
    {
        ID: 5,
        name: 'Friend'
    }
]

export const TargetDemograph = [
    {
        ID: 1,
        name: 'All/Per-Resident'
    },
    {
        ID: 2,
        name: 'Primary Occupant'
    },
    {
        ID: 3,
        name: 'Household'
    },
    {
        ID: 4,
        name: 'Building'
    }
]

export const TargetSeason = [
    {
        ID: 1,
        name: 'One-off'
    },
    {
        ID: 2,
        name: 'Daily'
    },
    {
        ID: 3,
        name: 'Bi-Weekly'
    },
    {
        ID: 4,
        name: 'Weekly'
    },
    {
        ID: 5,
        name: 'Bi-Monthly'
    },
    {
        ID: 6,
        name: 'Monthly'
    },
    {
        ID: 7,
        name: 'Bi-Annually'
    },
    {
        ID: 8,
        name: 'Annually'
    }
]
