import React, {useState, useEffect} from 'react'
import "./togglebuttn.css"
import { useSelector } from 'react-redux';

const Toggles = ({currentState, index, setmode}) => {

    // const { mode } = useSelector((state) => state.modeestate);

    // console.log(currentState)

    const [modee, setModee] = useState(false)   

    useEffect(() => {
        setModee(currentState === 1 ? true : false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentState])

    // useEffect(() => {
    //     setModee(newmode === 1 ? true : false)
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[newmode])
    
    const toggleState = () =>{
        setmode({id: index, status: modee ? 0 : 1 })
    }

    return (
        <div className='navbar-contain'>
            <div className = {(modee ? "toggle_contain_inv" : "toggle_contain")} onClick={toggleState}>
                {modee ? <div id="darkmode">A</div> : <div id="lightmode">IA</div> }
            </div>
        </div>
        
    );
}

export default Toggles