const streetJSON = [
    {
        ID: 1,
        name: 'ALAO Keyede Street'
    },
    {
        ID: 2,
        name: ' FESTUS ADERELE Street'
    },
    {
        ID: 3,
        name: ' ENGR. OGUNYEMI CLOSE'
    },
    {
        ID: 4,
        name: 'ARC DASAOLU CLOSE'
    },
    {
        ID: 5,
        name: 'ALH. SAKIRU Street'
    },
    {
        ID: 6,
        name: 'EJIKUNLE Street'
    },
    {
        ID: 7,
        name: 'OMOTOYE LANE'
    },
    {
        ID: 8,
        name: 'SOMOYE NORTH'
    },
    {
        ID: 9,
        name: 'SOMOYE South'
    },
    {
        ID: 10,
        name: 'NIYI DASAOLU CLOSE'
    },
    {
        ID: 11,
        name: 'SOMOYE South'
    }
]

export default streetJSON