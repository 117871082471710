const ResidentJSON = [
    {
        ID: 1,
        name: 'Landlord'
    },
    {
        ID: 2,
        name: 'Tenant'
    }
]

export default ResidentJSON