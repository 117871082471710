// capture button click
export const captureButtonClick = (currentpage) => ({
  type: "CAPTURE_BUTTON_CLICK",
  payload: currentpage,
});

export const captureStreetNav = (streetab) => ({
  type: "Add_New_Street",
  payload: streetab,
});

export const captureLevyNav = (levytab) => ({
  type: "Add_New_Levy",
  payload: levytab,
});

export const captureAddButtonClick = (currentpage) => ({
  type: "Add_New_Occupant",
  payload: currentpage,
});

export const captureListClick = (currentpage) => ({
  type: "Selected_Occupant",
  payload: currentpage,
});

export const sidebarToggleClick = (clickedtab) => ({
  type: "sidebar_Buttons",
  payload: clickedtab,
});

export const Navigation_Tab_Click = (clickedtab) => ({
  type: "navtab_Buttons",
  payload: clickedtab,
});
