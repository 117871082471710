import React, { useState } from "react";
import "../../index.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import { server } from "../../server";
import axios from "axios";
import ReactLoading from "react-loading";
import { Store } from "../../Redux/store";
import { getAllHouses, getAllOccupants, getAllStreet } from "../../Redux/Actions/dataResponse";

const AddStreet = ({ captureAddButtonClick }) => {
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    captureAddButtonClick(0);
  };

  const [formData, setFormData] = useState({
    name: "",
  });

  const clearFields = () => {
    setFormData({
      name: ""
    })
  };

  // Submit Street Form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      var config = {
        method: "POST",
        url: `${server}/streets`,
        data: formData,
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          setLoading(false);
          clearFields();
          toast.success(response.data.message);
          Store.dispatch(getAllOccupants(response.data.data.occupants));
          Store.dispatch(getAllStreet(response.data.data.street));
          Store.dispatch(getAllHouses(response.data.data.houses));
        }
      });
    } catch (e) {
      if(e.response.status === 500){
        toast.success("Street Already Exist");
      }else{
        toast.success(e.response.data.message);
      }
    }
  };

  return (
    <div className="w-full h-full absolute bg-[black] flex justify-center items-center overlaybackground">
      <div className="min-w-[300px] w-[60%] h-[60%] min-h-[300px] max-h-[350px] bg-[white] flex flex-col p-3">
        <div className="w-flex relative flex justify-end items-center">
          <AiOutlineCloseCircle
            fontSize={30}
            className="cursor-pointer"
            onClick={handleClose}
          />
        </div>

        <div className="w-full flex justify-center items-center">
          <span className="text-[16px] font-[800] mb-8">ADD STREET</span>
        </div>

        <form
          onSubmit={handleSubmit}
          className="w-full h-auto flex flex-col gap-9"
        >
          <input
            type="text"
            placeholder="Street Name"
            value={formData.name}
            required
            onChange={(e) =>
              setFormData({
                ...formData,
                name: e.target.value,
              })
            }
            className="placeholder-gray-500 placeholder-opacity-25 ... border min-w-[120px] grow p-2 text-[13px] rounded-sm w-full min-w-[200px] outline-none"
          />

          <div className="relative w-full h-[50px] flex items-center">
            <input
              disabled = {loading ? true : false}
              type="submit"
              value="SUBMIT"
              className="mt-5 min-w-[120px] grow p-2 text-[13px] font-bold rounded-sm w-full min-w-[200px] outline-none bg-[grey] hover:bg-[#ccc] text-[white] cursor-pointer"
            />
            {loading && (
              <ReactLoading
                className="absolute left-[65%] top-[40%]"
                type="spin"
                color="white"
                height={"6%"}
                width={"6%"}
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddStreet;
