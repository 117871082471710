import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  submitPersonalInfo,
  submitDependentInfo,
  submitPropertyInfo,
} from "../../Redux/Actions/Enrollform";
import Stepper from "react-stepper-horizontal";
import Logo from "../../assets/images/cda.jpg";
import UploadImg from "../Imageupload/UploadImg";
import ResidentJSON from "./residentJson";
import Singleselect from "../Singleselect/Singleselect";
import { genderJSON, titleJSON } from "./genderJson";
import {
  livingStatusJSON,
  maritalStatusJSON,
  rolesJSON,
  superRolesJSON,
} from "./maritalststuaJSON.js";
import apratmentJSON from "./apartmentJson";
import Assetsdata from "../../data/assets";
import Lottie from "lottie-react";
import animationData from "../../assets/animations/107043-success.json";
import { BsFillCheckCircleFill } from "react-icons/bs";
import streetJSON from "./streetJson";
import { AiOutlineSearch } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import "./searchstyle.css";
// import Occupantdata from "./occupantinfos.json";
import axios from "axios";
import { server } from "../../server.js";
import { toast } from "react-toastify";
import Loader from "../Loading/Loading.js";
import { RandomStringGenerator } from "../generateRandom.js";
import { getAllOccupants } from "../../Redux/Actions/dataResponse.js";
import { Store } from "../../Redux/store.jsx";
import Registeroptionmodal from "./Registeroptionmodal.js";
import {
  captureAddButtonClick,
  captureButtonClick,
  captureListClick,
} from "../../Redux/Actions/buttonClick.js";
// import { useCookies } from "react-cookie";

const Registration = () => {
  // const [cookies] = useCookies(["Token"]);
  const { occupant } = useSelector((state) => state.datareponse);
  const { userdata } = useSelector((state) => state.users);
  const { buttonClicks } = useSelector((state) => state.buttonclick);
  const { addoccupantprops } = useSelector((state) => state.buttonclick);
  const { selectedoccupant } = useSelector((state) => state.buttonclick);
  const { navtab } = useSelector((state) => state.buttonclick);
  console.log(userdata);

  const [activeStep, setActiveStep] = useState(0);
  const [search, setSearch] = useState(true);
  const [searchData, setSearchData] = useState(occupant);
  const [data, setData] = useState(occupant);
  const [hidesearchvisible, setHidesearchvisible] = useState(false);
  const [showOverlay, setShowOverlay] = useState(true);
  const [loader, setLoader] = useState(false);
  const [asset, setAsset] = useState(null);
  const [carmake, setCarmake] = useState(null);
  const [cartype, setCartype] = useState(null);
  const [occupantType, setOccupantType] = useState("");

  const [formData, setFormData] = useState({
    // Define your personal information fields here
    family_id: RandomStringGenerator(),
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    image_url: "",
    res_status: "",
    occupant_type: "",
    street_name: "",
    house_number: "",
    apartment_type: "",
    gender: "",
    maritalStatus: "",
    occupation: "",
    role: "",
    livingStatus: "",
    // ...
  });

  const [assetdata, setAssetdata] = useState({
    // Define your Asset information fields here
    asset: "",
    asset_type: "",
    engine_num: "",
    plate_num: "",
    make: "",
    model: "",
    year: "",
    color: "",
    mileage: "",
    engineType: "",
    vin: "",
    chasis_num: "",
    displacement: "",
    horsepower: "",
    family_id: formData.family_id,
    // ...
  });

  const [assetArray, setAssetArray] = useState([]);
  // const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  // const [searchResults, setSearchResults] = useState([]);
  const [searchfield, setSearchfield] = useState(false);
  const [rowcount, setRowcount] = useState(1);

  useEffect(() => {
    setShowOverlay(true);

    setOccupantType("Primary Occupant");
    setSearchData(occupant);
    setData(occupant);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [occupant, buttonClicks, navtab]);

  useEffect(() => {
    if (activeStep === 1) {
      setAssetArray((oldArray) => [...oldArray, { ...assetdata }]);
    }
  }, [activeStep, assetdata]);

  console.log(addoccupantprops);
  useEffect(() => {
    handleAddNew();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addoccupantprops]);

  useEffect(() => {
    if (selectedoccupant !== "non") {
      handleListselect(selectedoccupant);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedoccupant]);

  const handleSearchChange = (e) => {
    setSearchfield(true);
    const searchText = e.target.value.toLowerCase();
    setSearchTerm(searchText);
    setHidesearchvisible(true);
    const filteredResults = data.filter(
      (item) =>
        (
          item.firstName.toLowerCase() +
          " " +
          item.lastName.toLowerCase()
        ).includes(searchText) ||
        (
          item.lastName.toLowerCase() +
          " " +
          item.firstName.toLowerCase()
        ).includes(searchText) ||
        item.email.toLowerCase().includes(searchText) ||
        item.family_id.toLowerCase().includes(searchText) ||
        item.phone.toLowerCase().includes(searchText)
    );
    setSearchData(filteredResults);
    setFormData({ ...formData, ...filteredResults[0] });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (activeStep === 0) {
      if (
        formData.firstName !== "" &&
        formData.lastName !== "" &&
        formData.email !== "" &&
        formData.phone !== ""
      ) {
        saveFirstStage();
      } else {
        toast.warning("Please Fill all required fields!");
      }
    } else if (activeStep === 1) {
      saveSecondSatage();
    }
  };

  const handleClick = () => {
    setRowcount(rowcount + 1);
    setAssetArray((oldArray) => [...oldArray, { ...assetdata }]);
  };

  // Remove from Asset List
  const handleReduceClick = () => {
    if (assetArray.length > 1) {
      setAssetArray((prevAssets) => {
        const updatedAssets = [...prevAssets];
        updatedAssets.pop();
        return updatedAssets;
      });
    }
  };

  const handleStepsBackward = () => {
    setActiveStep(activeStep - 1);
  };

  // Submit Occupant FormsaveFirstSatage
  const saveFirstStage = async () => {
    setLoader(true);
    try {
      var config = {
        method:
          addoccupantprops.occupantype === "Dependant"
            ? "POST"
            : addoccupantprops.occupantype === "Primary Occupant"
            ? "POST"
            : "PUT",
        url:
          addoccupantprops.occupantype === "Dependant"
            ? `${server}/occupants`
            : addoccupantprops.occupantype === "Primary Occupant"
            ? `${server}/occupants`
            : `${server}/occupants/${formData.ID}`,
        data: formData,
      };

      console.log(config);

      await axios(config).then((response) => {
        if (response.status === 201) {
          Store.dispatch(getAllOccupants(response.data.updatedRecords));
          setLoader(false);
          toast.success(response.data.message);
          setActiveStep(activeStep + 1);
        }
      });
    } catch (e) {
      console.log(e);
      // toast.success(e.response.data.message);
    }
  };

  // Submit Asset Form
  const saveSecondSatage = async () => {
    try {
      var config = {
        method: "POST",
        url: `${server}/assets`,
        data: assetArray,
      };

      console.log(config);

      await axios(config).then((response) => {
        if (response.data.status === 201) {
          toast.success(response.data.message);
          setActiveStep(activeStep + 1);
        }
      });
    } catch (e) {
      toast.success(e.response.data.message);
    }
  };

  // const selectOccupant = (incoming) => {
  //   setFormData({ ...formData, occupant_type: incoming });
  //   if (incoming === "Dependant") {
  //     setOccuptype(true);
  //   } else {
  //     setOccuptype(false);
  //   }
  // };

  const handleSelected = (incoming, e) => {
    setFormData({ ...formData, [incoming]: e });
  };

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    const updatedAssets = [...assetArray];
    updatedAssets[index] = {
      ...updatedAssets[index],
      [name]: value,
    };
    setAssetArray(updatedAssets);
  };

  const handleSelectedAsset = (incoming, e) => {
    // setAssetdata({ ...assetdata, [incoming]: e });
    const value = e;
    const name = incoming.name;
    const index = incoming.index;
    const updatedAssets = [...assetArray];
    updatedAssets[index] = {
      ...updatedAssets[index],
      [name]: value,
    };
    setAssetArray(updatedAssets);

    if (e === "Vehicle") {
      setAsset("vehicle");
    } else if (e === "Motocycle") {
      setAsset("motocycle");
    } else if (e === "Bicycle") {
      setAsset("bicycle");
    } else if (e === "Toyota") {
      setCarmake("toyota");
    } else if (e === "Lexus") {
      setCarmake("lexus");
    } else if (e === "Pegeuot") {
      setCarmake("pegeuot");
    } else if (e === "SUV") {
      setCartype("suv");
    } else if (e === "Sedan") {
      setCartype("sedan");
    }
  };

  const steps = [
    { title: "Personal Information" },
    { title: "Asset Information" },
    { title: "Confirmation" },
  ];

  // const getSectionComponent = () => {
  //   switch (activeStep) {
  //     case 0:
  //       return <Personalinformation />;
  //     case 1:
  //       return <Assetinformation />;
  //     case 2:
  //       return <Confirmation />;
  //     default:
  //       return null;
  //   }
  // };

  // const handleSearchChange = (e) => {
  //   let term = e.target.value;
  //   setSearchTerm(term);
  //   setHidesearchvisible(true);

  //   // Filter the array based on the first_name property
  //   const filteredData = streetJSON.filter((item) =>
  //     item.houses.some((house) =>
  //       house.house_info.some((info) =>
  //         info.occupant_info.some(
  //           (occupant) =>
  //             occupant.first_name.toLowerCase().indexOf(term?.toLowerCase()) !==
  //             -1
  //         )
  //       )
  //     )
  //   );
  //   setSearchData(filteredData);
  // };

  const handleSearchClick = () => {
    Store.dispatch(
      captureAddButtonClick({
        occupantype: "",
      })
    );

    if (searchTerm !== "") {
      setSearchTerm("");
      setShowOverlay(false);
    } else {
      toast.warning("Enter a search term");
    }
  };

  const handleAddNew = (occupantype) => {
    if (occupantype === "Dependant") {
      setFormData({
        // Define your personal information fields here
        family_id: formData.family_id,
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phone: "",
        image_url: "",
        res_status: formData.res_status,
        occupant_type: formData.occupant_type,
        street_name: formData.street_name,
        house_number: formData.house_number,
        apartment_type: formData.apartment_type,
        gender: "",
        maritalStatus: "",
        occupation: "",
        // ...
      });
      setActiveStep(0);
      setShowOverlay(false);
    } else if (occupantype === "Primary Occupant") {
      setFormData({
        // Define your personal information fields here
        family_id: RandomStringGenerator(),
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phone: "",
        image_url: "",
        res_status: "",
        occupant_type: "",
        street_name: "",
        house_number: "",
        apartment_type: "",
        gender: "",
        maritalStatus: "",
        occupation: "",
        // ...
      });
      setShowOverlay(false);
      setActiveStep(0);
    } else if (occupantype === "Asset") {
      setActiveStep(1);
      setAssetdata({
        // Define your Asset information fields here
        asset: "",
        asset_type: "",
        engine_num: "",
        plate_num: "",
        make: "",
        model: "",
        year: "",
        color: "",
        mileage: "",
        engineType: "",
        vin: "",
        chasis_num: "",
        displacement: "",
        horsepower: "",
        family_id: formData.family_id,
        // ...
      });
      setShowOverlay(false);
    }
  };

  const handleBack = () => {
    // Store.dispatch(captureButtonClick("close"));
    Store.dispatch(captureListClick("non"));
    setFormData({
      // Define your personal information fields here
      family_id: "",
      title: "",
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      image_url: "",
      res_status: "",
      occupant_type: "",
      street_name: "",
      house_number: "",
      apartment_type: "",
      gender: "",
      maritalStatus: "",
      occupation: "",
      // ...
    });
    setAssetArray([]);
    setSearchTerm("");
    setShowOverlay(true);
  };

  const handleListselect = (incoming) => {
    const selectedOccupant = {
      ...incoming,
      occupant_type: occupantType,
      family_id:
        incoming.family_id === ""
          ? RandomStringGenerator()
          : incoming.family_id,
    };
    setSearchTerm(incoming.firstName + " " + incoming.lastName);
    setFormData({ ...formData, ...selectedOccupant });
    setHidesearchvisible(false);
  };

  const handleMouseLeave = () => {
    setHidesearchvisible(!hidesearchvisible);
  };

  const handleToggleSelection = () => {
    // setLoaderror({
    //     statusid: 0,
    //     message: ''
    // })
  };

  const handledirectoryUpdate = (incoming) => {
    setFormData({ ...formData, image_url: incoming });
  };

  const style = {
    height: 200,
  };

  return (
    <>
      {showOverlay && userdata.role.includes("Admin") && (
        <div
          className={`bg-[#f9f9f9] min-w-[450px] absolute top-0 left-0 w-full h-full flex items-center justify-center transition-transform duration-500 z-20 ${
            showOverlay ? "" : "transform-translate-y-full"
          }`}
        >
          <div
            className={`flex flex-col  h-full w-full  py-5 top-0 left-0 items-center justify-center transition-transform duration-500`}
          >
            {/* {buttonClicks === "open" && <Registeroptionmodal />} */}

            {navtab === 1 ? (
              <Registeroptionmodal addnew_occupant={handleAddNew} />
            ) : (
              <div className="w-full min-h-[200px] flex flex-col justify-start items-center">
                <div className="mt-8 w-full flex justify-center gap-2 items-center relative">
                  <div
                    className={
                      search ? "searchbarContain" : "searchbarContainvis"
                    }
                  >
                    <AiOutlineSearch id="searchicon" />
                    <input
                      className="searchfield overflow-hidden"
                      type="text"
                      placeholder="Search by name"
                      onChange={handleSearchChange}
                      value={searchTerm}
                    />

                    {(searchData?.length !== 0 ||
                      occupantType === "Dependant") && (
                      <div
                        onClick={handleSearchClick}
                        className="h-full px-3 flex justify-center items-center bg-[#333] absolute right-[0px] text-[20px] cursor-pointer"
                      >
                        <AiOutlineSearch size={20} color="#fff" />
                      </div>
                    )}

                    <div
                      className={
                        searchTerm !== "" && hidesearchvisible === true
                          ? "max-h-[30vh] bg-slate-50 shadow-sm-2 z-[9] py-2 search_filetered_container overflow-y-auto"
                          : "hidden"
                      }
                      onMouseLeave={handleMouseLeave}
                    >
                      {searchData?.length > 0 ? (
                        searchData?.map((item, index) => (
                          <h1
                            onClick={(e) => handleListselect(item)}
                            className="text-[13px] hover:bg-[#fff] px-2 py-1 cursor-pointer whitespace-nowrap text-ellipsis overflow-hidden"
                            key={index}
                          >
                            {item.firstName + " " + item.lastName}
                          </h1>
                        ))
                      ) : (
                        <div className="text-[13px] hover:bg-[#fff] px-2 py-1 cursor-pointer whitespace-nowrap text-ellipsis overflow-hidden flex items-center justify-between">
                          <h1>Name does Not Exist</h1>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="flex w-full flex-col bg-[#f9f9f9] gap-2 py-4 min-h-screen min-w-[550px]relative">
        <div className="flex relative items-center justify-center gap-5">
          {/* <img src={Logo} className="w-[60px] h-[60px] rounded-full" alt="" /> */}

          {/* <div className="flex flex-col">
            <h2 className="mt-2 text-2xl font-bold text-[#333]-900 text-center">
              OMOTOYE ESTATE
            </h2>
            <h2 className=" text-xl text-[#ccc]-500 text-center">
              Community Development Association
            </h2>
          </div> */}
        </div>

        <div className="w-full flex items-center justify-center px-4">
          <Stepper steps={steps} activeStep={activeStep} />
        </div>

        <div className="px-[40px] py-[20px]">
          {activeStep === 0 ? (
            <div className="h-full flex flex-col gap-5">
              <div className="pb-4">
                <div className="flex flex-col gap-2">
                  <div className="flex justify-center mb-3">
                    <UploadImg
                      fetchedurl={formData.image_url}
                      onfocus={handleToggleSelection}
                      directory={handledirectoryUpdate}
                    />
                  </div>

                  <div className="w-full flex-wrap flex items-center justify-between gap-2">
                    <div className="flex flex-col w-[70px] gap-1 items-left">
                      <div className="flex items-center gap-2 text-[13px] text-[gray]">
                        <label>Title</label>
                        <p className="text-[red]">*</p>
                      </div>

                      <Singleselect
                        dd_value={formData.title}
                        socialjs={titleJSON}
                        selectedSocVal={(e) => handleSelected("title", e)}
                      />
                    </div>

                    <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                      <div className="flex items-center gap-2 text-[13px] text-[gray]">
                        <label>Firstname</label>
                        <p className="text-[red]">*</p>
                      </div>

                      <input
                        type="text"
                        placeholder="First Name"
                        value={formData.firstName}
                        required
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            firstName: e.target.value,
                          })
                        }
                        className="placeholder-gray-500 placeholder-opacity-25 ... border min-w-[120px] grow p-2 text-[13px] rounded-sm w-full min-w-[200px] outline-none"
                      />
                    </div>

                    <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                      <div className="flex items-center gap-2 text-[13px] text-[gray] text-[gray]">
                        <label>Middlename</label>
                      </div>

                      <input
                        type="text"
                        placeholder="Middle Name"
                        value={formData.middleName}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            middleName: e.target.value,
                          })
                        }
                        className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                      />
                    </div>

                    <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                      <div className="flex items-center gap-2 text-[13px] text-[gray]">
                        <label>Lastname</label>
                        <p className="text-[red]">*</p>
                      </div>

                      <input
                        type="text"
                        placeholder="Last Name"
                        value={formData.lastName}
                        required
                        onChange={(e) =>
                          setFormData({ ...formData, lastName: e.target.value })
                        }
                        className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                      />
                    </div>
                  </div>

                  <div className="w-full flex flex-wrap items-center justify-between gap-2">
                    <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                      <div className="flex items-center gap-2 text-[13px] text-[gray]">
                        <label>Email</label>
                        <p className="text-[red]">*</p>
                      </div>
                      <input
                        type="email"
                        placeholder="Email"
                        value={formData.email}
                        required
                        onChange={(e) =>
                          setFormData({ ...formData, email: e.target.value })
                        }
                        className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                      />
                    </div>

                    <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                      <div className="flex items-center gap-2 text-[13px] text-[gray]">
                        <label>Telephone</label>
                        <p className="text-[red]">*</p>
                      </div>
                      <input
                        type="text"
                        placeholder="Phone number"
                        value={formData.phone}
                        required
                        onChange={(e) =>
                          setFormData({ ...formData, phone: e.target.value })
                        }
                        className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                      />
                    </div>
                  </div>

                  <div className="w-full flex flex-wrap items-center justify-between gap-2">
                    <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                      <div className="flex items-center gap-2 text-[13px] text-[gray]">
                        <label>Street Name</label>
                        <p className="text-[red]">*</p>
                      </div>

                      <Singleselect
                        dd_value={formData.street_name}
                        socialjs={streetJSON}
                        selectedSocVal={(e) => handleSelected("street_name", e)}
                        placeholder="Select Street"
                      />
                    </div>

                    <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                      <div className="flex items-center gap-2 text-[13px] text-[gray]">
                        <label>House Number</label>
                        <p className="text-[red]">*</p>
                      </div>

                      <input
                        type="text"
                        placeholder="House Number"
                        value={formData.house_number}
                        required
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            house_number: e.target.value,
                          })
                        }
                        className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                      />
                    </div>

                    <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                      <div className="flex items-center gap-2 text-[13px] text-[gray]">
                        <label>Apartment Type</label>
                        <p className="text-[red]">*</p>
                      </div>

                      <Singleselect
                        dd_value={formData.apartment_type}
                        socialjs={apratmentJSON}
                        selectedSocVal={(e) =>
                          handleSelected("apartment_type", e)
                        }
                        placeholder="Select Apartment type"
                      />
                    </div>
                  </div>

                  <div className="w-full flex flex-wrap items-center justify-between gap-2">
                    <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                      <div className="flex items-center gap-2 text-[13px] text-[gray]">
                        <label>Gender</label>
                        <p className="text-[red]">*</p>
                      </div>

                      <Singleselect
                        dd_value={formData.gender}
                        socialjs={genderJSON}
                        selectedSocVal={(e) => handleSelected("gender", e)}
                        placeholder="Select Gender"
                      />
                    </div>

                    <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                      <div className="flex items-center gap-2 text-[13px] text-[gray]">
                        <label>Resident status</label>
                        <p className="text-[red]">*</p>
                      </div>

                      <Singleselect
                        dd_value={formData.res_status}
                        socialjs={ResidentJSON}
                        selectedSocVal={(e) => handleSelected("res_status", e)}
                        placeholder="Select Resident status"
                      />
                    </div>
                  </div>

                  <div className="w-full flex flex-wrap items-center justify-between gap-2">
                    <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                      <div className="flex items-center gap-2 text-[13px] text-[gray]">
                        <label>Martial Status</label>
                        <p className="text-[red]">*</p>
                      </div>

                      <Singleselect
                        dd_value={formData.maritalStatus}
                        socialjs={maritalStatusJSON}
                        selectedSocVal={(e) =>
                          handleSelected("maritalStatus", e)
                        }
                        placeholder="Select Marital Status"
                      />
                    </div>

                    <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                      <div className="flex items-center gap-2 text-[13px] text-[gray]">
                        <label>Occupation</label>
                        <p className="text-[red]">*</p>
                      </div>
                      <input
                        type="text"
                        placeholder="Occupation"
                        value={formData.occupation}
                        required
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            occupation: e.target.value,
                          })
                        }
                        className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                      />
                    </div>
                  </div>

                  <div className="w-full flex flex-wrap items-center justify-between gap-2">
                    <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                      <div className="flex items-center gap-2 text-[13px] text-[gray]">
                        <label>Role</label>
                        <p className="text-[red]">*</p>
                      </div>

                      <Singleselect
                        dd_value={formData.role}
                        socialjs={
                          userdata.role.includes("super")
                            ? superRolesJSON
                            : rolesJSON
                        }
                        selectedSocVal={(e) => handleSelected("role", e)}
                        placeholder="Assign Role"
                      />
                    </div>

                    <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                      <div className="flex items-center gap-2 text-[13px] text-[gray]">
                        <label>Living Status</label>
                        <p className="text-[red]">*</p>
                      </div>
                      <Singleselect
                        dd_value={formData.livingStatus}
                        socialjs={livingStatusJSON}
                        selectedSocVal={(e) =>
                          handleSelected("livingStatus", e)
                        }
                        placeholder="Living Status"
                      />
                    </div>

                    <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                      <div className="flex items-center gap-2 text-[13px] text-[gray]">
                        <label>Family ID</label>
                        <p className="text-[red]">*</p>
                      </div>
                      <input
                        type="text"
                        value={formData.family_id}
                        disabled
                        className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : activeStep === 1 ? (
            <div className="h-full flex flex-col gap-5">
              {assetArray.map((items, index) => (
                <div key={index} className="p-3 bg-[#f9f5f9] border">
                  <div className="flex flex-col gap-2">
                    <div className="w-full flex-wrap flex items-center justify-between gap-2">
                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px]">
                          <label>Asset</label>
                        </div>

                        <Singleselect
                          socialjs={Assetsdata}
                          selectedSocVal={(e) =>
                            handleSelectedAsset({ name: "asset", index }, e)
                          }
                          placeholder="Select Asset"
                        />
                      </div>

                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px]">
                          <label>Type</label>
                        </div>

                        <Singleselect
                          // dd_value={ass.type}
                          socialjs={Assetsdata[0].vehicle_type}
                          selectedSocVal={(e) =>
                            handleSelectedAsset(
                              { name: "asset_type", index },
                              e
                            )
                          }
                          placeholder="Select Type"
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-wrap items-center justify-between gap-2">
                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px]">
                          <label>Make</label>
                        </div>

                        <Singleselect
                          socialjs={
                            cartype === "suv"
                              ? Assetsdata[0].vehicle_type[0].vehicle_make
                              : Assetsdata[0].vehicle_type[1].vehicle_make
                          }
                          selectedSocVal={(e) =>
                            handleSelectedAsset({ name: "make", index }, e)
                          }
                          placeholder="Select make"
                        />
                      </div>

                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px]">
                          <label>Model</label>
                        </div>
                        <Singleselect
                          socialjs={
                            carmake === "toyota" && cartype === "suv"
                              ? Assetsdata[0].vehicle_type[0].vehicle_make[0]
                                  .model
                              : carmake === "lexus" && cartype === "suv"
                              ? Assetsdata[0].vehicle_type[0].vehicle_make[1]
                                  .model
                              : carmake === "toyota" && cartype === "sedan"
                              ? Assetsdata[0].vehicle_type[1].vehicle_make[0]
                                  .model
                              : Assetsdata[0].vehicle_type[1].vehicle_make[1]
                                  .model
                          }
                          selectedSocVal={(e) =>
                            handleSelectedAsset({ name: "model", index }, e)
                          }
                          placeholder="Select Model"
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-wrap items-center justify-between gap-2">
                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px]">
                          <label>Engine Number</label>
                        </div>

                        <input
                          type="text"
                          placeholder="Engine Number"
                          value={items.engine_num || ""}
                          name="engine_num"
                          onChange={(e) => handleChange(e, index)}
                          className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-wrap items-center justify-between gap-2">
                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px]">
                          <label>Plate Number</label>
                        </div>
                        <input
                          type="text"
                          placeholder="Plate number"
                          value={items.plate_num || ""}
                          name="plate_num"
                          onChange={(e) => handleChange(e, index)}
                          className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-wrap items-center justify-between gap-2">
                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px]">
                          <label>Color</label>
                        </div>

                        <input
                          type="text"
                          placeholder="color"
                          value={items.color || ""}
                          name="color"
                          onChange={(e) => handleChange(e, index)}
                          className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                        />
                      </div>

                      <div className="flex flex-col min-w-[200px] grow gap-1 items-left">
                        <div className="flex items-center gap-2 text-[13px]">
                          <label>Year</label>
                        </div>
                        <input
                          type="text"
                          placeholder="year"
                          value={items.year || ""}
                          name="year"
                          onChange={(e) => handleChange(e, index)}
                          className="placeholder-gray-500 placeholder-opacity-25 ... border w-full min-w-[120px] grow p-2 text-[13px] rounded-sm outline-none"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div className="flex gap-2 items-center my-2">
                <div
                  onClick={handleClick}
                  className="p-2 rounded-full flex items-center justify-center bg-[#1F3864] cursor-pointer"
                >
                  <FaPlus size={13} color="#fff" />
                </div>

                <div
                  onClick={handleReduceClick}
                  className="p-2 rounded-full flex items-center justify-center bg-[#1F3864] cursor-pointer"
                >
                  <MdDeleteOutline size={13} color="#fff" />
                </div>
              </div>
            </div>
          ) : activeStep === 2 ? (
            <div className="h-full w-full flex justify-center items-center flex-col gap-5">
              <Lottie animationData={animationData} style={style} />

              <div className="w-full flex items-center justify-center gap-3">
                <BsFillCheckCircleFill color="green" size={25} />
                <h5 className="text-center text-[16px] font-[600] text-[green]">
                  Registration Complete! 😍
                </h5>
              </div>

              <div
                onClick={handleBack}
                className="px-5 py-2 text-[#fff] cursor-pointer bg-[#007FFF] rounded text-[14px] hover:shadow-sm"
              >
                Begin Fresh
              </div>
            </div>
          ) : null}

          <div className="flex gap-2 justify-between items-center w-full">
            {/* {activeStep === 0 && ( */}
            <button
              className="px-3 cursor-pointer py-2 bg-[grey] text-[white] text-[13px] font-[600] mt-5 mr-2"
              onClick={handleBack}
            >
              Back
            </button>

            {/* {activeStep !== 0 && activeStep !== steps.length - 1 && (
              <button
                className="px-3 cursor-pointer py-2 bg-[grey] text-[white] text-[13px] font-[600] mt-5 mr-2"
                onClick={handleStepsBackward}
              >
                Previous
              </button>
            )} */}

            <div className="flex gap-1 items-center w-full justify-end">
              {activeStep !== steps.length - 1 && (
                <button
                  // disabled={loader ? true : false}
                  className="flex items-center px-3 cursor-pointer py-2 bg-[grey] text-[white] text-[13px] font-[600] mt-5"
                  onClick={handleFormSubmit}
                >
                  {loader && (
                    <Loader
                      type="spin"
                      color="white"
                      text=""
                      height="100%"
                      width="100%"
                    />
                  )}
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;
