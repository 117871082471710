import React, { useRef, useEffect } from "react";
import { useState } from "react";
import assets from "../../assets/images/assets.gif";
import axios from "axios";
import { server } from "../../server.js";

export default function UploadImg({ fetchedurl, directory, onfocus }) {
  useEffect(() => {
    setLocalUrl(fetchedurl === undefined ? null : fetchedurl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedurl]);

  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [localUrl, setLocalUrl] = useState(null);

  const hiddenFileInput = useRef(null);

  const handlePictureUpload = () => {
    hiddenFileInput.current.click();
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  function uploadSingleImage(base64) {
    // setLoading(true);
    setLoading(false);
    axios
      .post(`${server}/images/uploadImage`, { image: base64 })
      .then((res) => {
        directory(res.data);
        // mylocalUrl(res.data);
      })
      .then(() => setLoading(false))
      .catch(console.log);
  }

  function uploadMultipleImages(images) {
    setLoading(true);
    axios
      .post(`${server}/images/uploadMultipleImages`, { images })
      .then((res) => {
        setUrl(res.data);
        alert("Image uploaded Succesfully");
      })
      .then(() => setLoading(false))
      .catch(console.log);
  }

  const uploadImage = async (event) => {
    onfocus();
    const files = event.target.files;
    setLocalUrl(URL.createObjectURL(event.target.files[0]));
    // console.log(files.length);

    if (files.length === 1) {
      const base64 = await convertBase64(files[0]);
      uploadSingleImage(base64);
      return;
    }

    const base64s = [];
    for (var i = 0; i < files.length; i++) {
      var base = await convertBase64(files[i]);
      base64s.push(base);
    }
    // uploadMultipleImages(base64s);
  };

  function UploadInput() {
    return (
      <div className="w-full flex justify-center flex-col items-center p-[5px] relative gap-[5px]">
        <img
          className="h-[140px] w-[140px] rounded-full bg-[#cccc] bg-center bg-no-repeat bg-cover text-center cursor-pointer sm:h-[110px] sm:w-[110px]"
          onClick={handlePictureUpload}
          src={localUrl}
          alt=""
        />
        <button
          className="absolute text-[#fff] text-[13px] font-[800]"
          onClick={handlePictureUpload}
        >
          Select Photo
        </button>
        <input
          accept="image/*"
          type="file"
          onChange={uploadImage}
          ref={hiddenFileInput}
          style={{ display: "none" }}
        />
      </div>
    );
  }

  return (
    <div>
      <div>
        {loading ? (
          <div className="w-full flex flex-col items-center p-[5px] relative gap-[5px]">
            <img
              src={assets}
              alt="alternative"
              className="h-[150px] w-[150px] rounded-full bg-[#8888] bg-center bg-no-repeat bg-cover text-center cursor-pointer"
            />{" "}
            <button
              disabled={true}
              className="add_emp_butn-upload"
              onClick={handlePictureUpload}
            >
              Select Photo
            </button>
          </div>
        ) : (
          <UploadInput />
        )}
      </div>
    </div>
  );
}
