import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

const Hero = ({
  appType,
  tagLine,
  description,
  mainActionText,
  extraActionText,
  img,
}) => {
  useEffect(() => {
    const noSelectElements = document.querySelectorAll("#product");
    noSelectElements.forEach((element) => {
      element.style.webkitUserSelect = "none";
      element.style.mozUserSelect = "none";
      element.style.msUserSelect = "none";
      element.style.userSelect = "none";
    });
  }, []);

  const { ref, inView, entry } = useInView();

  return (
    <div id="product">
      <div
        style={{ textShadow: "0px 1px 1px gray" }}
        className={`flex flex-col items-center justify-start min-h-[80vh] bg-gray-50 ${img} lg:bg-cover lg:pt-10 lg:pb-20 pt-10 bg-center bg-no-repeat bg-cover`}
        ref={ref}
      >
        <div>
          <p
            className={`${
              inView && "animate_text_1"
            } p-3 pt-12 text-lg font-bold text-gray-300`}
          >
            {appType}
          </p>
        </div>

        <div className="w-full flex text-center">
          <p
            className={`${
              inView && "animate_text_1"
            } p-2 text-4xl font-bold text-center text-[white] mx-auto w-5/6`}
          >
            {tagLine}
          </p>
        </div>

        <div>
          <p
            className={`${
              inView && "animate_text_2"
            } p-4 pt-6 text-2xl leading-10 text-center  text-gray-200`}
          >
            {description}
          </p>
        </div>

        <div>
          <button>
            <Link
              className="text-[white] font-bold text-[15px] mt-5 py-3 px-9 bg-[orange] rounded-full tracking-wide shadow-md hover:shadow-xl hover:bg-white hover:text-[orange] border ring-offset-2 ring-2"
              to="/login"
            >
              EXPLORE
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
