import { createReducer } from "@reduxjs/toolkit";

// reducers.js
const initialState = {
  buttonClicks: '',
  addoccupantprops: {},
  selectedoccupant: 'non',
  clickedtab: 'tab_a',
  navtab: 1,
  streetnavtab: 1,
  levynavtab: 1
};


export const buttonClickReducer = createReducer(initialState, {
  CAPTURE_BUTTON_CLICK: (state, action) => {
      state.buttonClicks = action.payload;
    },

    Add_New_Occupant: (state, action) => {
      state.addoccupantprops = action.payload 
    },

    Selected_Occupant: (state, action) => {
      state.selectedoccupant = action.payload
    },

    sidebar_Buttons: (state, action) => {
      state.clickedtab = action.payload
    },

    navtab_Buttons: (state, action) => {
      state.navtab = action.payload
    },

    Add_New_Street: (state, action) => {
      state.streetnavtab = action.payload
    },

    Add_New_Levy: (state, action) => {
      state.levynavtab = action.payload
    }
  });