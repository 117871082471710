const FormatDateAndTime = (inputDate) => {
  const dateObj = new Date(inputDate);

  // Format the date
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(dateObj);

  // Format the time
  const hours = dateObj.getHours().toString().padStart(2, '0');
  const minutes = dateObj.getMinutes().toString().padStart(2, '0');
  const seconds = dateObj.getSeconds().toString().padStart(2, '0');
  const formattedTime = `${hours}:${minutes}:${seconds}`;

  // Get the day suffix (e.g., "st", "nd", "rd", "th")
  const day = dateObj.getDate();
  const daySuffix =
    day % 10 === 1 && day !== 11
      ? 'st'
      : day % 10 === 2 && day !== 12
      ? 'nd'
      : day % 10 === 3 && day !== 13
      ? 'rd'
      : 'th';

  // Construct the final object
  const result = {
    date: `${day}${daySuffix}, ${formattedDate}`,
    time: formattedTime,
  };

  return result;
};

export default FormatDateAndTime;
