import React, { useEffect } from "react";
import Occupantlist from "../Components/Occupants/Occupantlist";
import Navigate from "../Components/Navigations/Navigate";
import { useSelector } from "react-redux";
import Relatedoccupant from "../Components/Occupants/Relatedoccupant";
import Registration from "../Components/Registration/Registration";
import Updaterecord from "../Components/Registration/Updaterecord";
import Navigationtab from "../Components/Navigations/Navigationtab";

const Occupants = () => {
  const { navtab } = useSelector((state) => state.buttonclick);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navtab]);

  console.log(navtab);
  return (
    <div className="w-full pr-6 mx-3 h-full flex flex-col min-w-[500px] gap-2">
      <Navigationtab />

      {navtab === 1 ? (
        <div className="w-full h-full relative">
          <Registration />
        </div>
      ) : (
        <div className="w-full h-full relative">
          <Registration />
        </div>
      )}
    </div>
  );
};

export default Occupants;
