import React, { useState, useEffect } from "react";
import { BiExport, BiImport } from "react-icons/bi";
import ButtonPagenate from "../ButtonPagenate/ButtonPagenate";
import { useSelector } from "react-redux";
import data from "./outstandingPay.json";
import FormatDateAndTime from "../formatDateTime";

const MyLevy = () => {
  const [outstandingpayment, setOutstandingpayment] = useState(
    data.outstandingpayment
  );
  const { searchdata } = useSelector((state) => state.searchfilter);

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerpage = 20; //Number of items to be displayed per page
  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const pageCount = Math.ceil(outstandingpayment?.length / itemsPerpage);
  const pagesVisited = pageNumber * itemsPerpage;

  var newdataset = outstandingpayment?.filter((filteredItem) => {
    if (searchdata === "") {
      return filteredItem;
    } else if (
      filteredItem.levy_label.toLowerCase().includes(searchdata) ||
      filteredItem.targetdemograph.toLowerCase().includes(searchdata)
    ) {
      return filteredItem;
    }
  });

  return (
    <div className="w-full h-full flex flex-col items-center bg-[#f9f9f9] gap-1">
      <div className="bg-[white] shadow p-1 w-full flex items-center justify-end gap-1">
        <div className="text-[white] bg-[#8c40c7] text-[12px] flex items-center cursor-pointer p-2 px-4 text-center rounded-[3px] hover:shadow">
          <BiImport size={18} className="mr-2" color="white" />
          Import
        </div>

        <div className="text-[white] bg-[#48c75b] text-[12px] flex items-center cursor-pointer p-2 px-4 text-center rounded-[3px] hover:shadow">
          <BiExport size={18} className="mr-2" color="white" />
          Export
        </div>
      </div>

      <div className="w-full bg-[white] min-h-[70vh] flex flex-col gap-5 max-w-screen h-full overflow-x-auto relative">
        <table className="bnw-claims-table">
          <thead id="bnw-claims-table-head">
            <tr className="text-[13px] uppercase w-[80px]">
              <th scope="col">LEVY NAME</th>
              <th scope="col">AMOUNT</th>
              <th scope="col">ASSIGNED TO</th>
              <th scope="col">DATE</th>
              <th scope="col" className="w-[30px]">
                ACTION
              </th>
            </tr>
          </thead>

          <tbody>
            {newdataset
              ?.slice(pagesVisited, pagesVisited + itemsPerpage)
              .map((item, index) => (
                <tr
                  key={index}
                  className="cursor-pointer text-[12px] font-normal"
                >
                  <td data-label="LEVY NAME">{item.levy_label}</td>
                  <td data-label="AMOUNT">{item.amount}</td>
                  <td data-label="ASSIGNED TO">{item.assignedto}</td>
                  <td data-label="DATE">
                    {FormatDateAndTime(item.updatedAt).date}
                  </td>

                  <td
                    data-label="ACTION"
                    className="flex justify-center w-full"
                  >
                    <div
                      className={`flex text-center rounded-[5px] bg-[red] text-[white] items-center justify-center p-2 max-w-[120px] w-full cursor-pointer`}
                    >
                      Pay Now
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <div className="w-fit flex">
          <ButtonPagenate
            updatedPageNum={newPagenum}
            pageCount={pageCount}
            pageNumber={pageNumber}
            previousLabel="Prev"
            nextLabel="Next"
          />
        </div>
      </div>
    </div>
  );
};

export default MyLevy;
