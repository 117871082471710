import React, { useState } from "react";
import "./Buttonpagenate.css";
import { BiSolidChevronsLeft, BiSolidChevronsRight } from "react-icons/bi";

const ButtonPagenate = ({
  updatedPageNum,
  pageCount,
  pageNumber,
  previousLabel,
  nextLabel,
}) => {
  const [initialbut, setInitialbut] = useState(1);
  // const [pageNumber, setPageNumber] = useState(setpageNumber,);

  const handleClick = (e, page) => {
    if (e.target.name === "but") {
      setInitialbut(page);
      updatedPageNum(page - 1);
    }

    if (e.target.name === "next") {
      if (pageCount > 1 && pageNumber < pageCount - 1) {
        setInitialbut(6);
        updatedPageNum(pageNumber + 1);
        setInitialbut(initialbut + 1);
      }
    }

    if (e.target.name === "prev") {
      if (pageCount !== 0 && pageNumber !== 0) {
        setInitialbut(7);
        updatedPageNum(pageNumber - 1);
        setInitialbut(initialbut - 1);
      }
    }
  };
  return (
    <div className="toggle-button">
      <button className="nav-button relative">
        <BiSolidChevronsLeft />
        <button
          name="prev"
          className="w-full h-full absolute"
          onClick={handleClick}
        />
      </button>

      <button
        className={`${
          initialbut === 1 ? "nav-button activess" : "nav-button"
        } p-2 px-3 text-[13px] font-[600] border-1 border-grey-300/100 hover:shadow-md relative`}
      >
        {" "}
        1{" "}
        <button
          name="but"
          className="w-full h-full absolute"
          onClick={(e) => handleClick(e, 1)}
        />
      </button>

      {pageCount > 1 && pageCount < 3 && (
        <button
          className={`${
            initialbut === 2 ? "nav-button activess" : "nav-button"
          } p-2 px-3 text-[13px] font-[600] border-1 border-grey-300/100 hover:shadow-md relative`}
        >
          {" "}
          2{" "}
          <button
            name="but"
            className="w-full h-full absolute"
            onClick={(e) => handleClick(e, 2)}
          />
        </button>
      )}
      {pageCount > 2 && (
        <>
          <button
            className={`${
              initialbut > 3 && initialbut < pageCount
                ? "nav-button activess"
                : "nav-button"
            } p-2 px-3 text-[13px] font-[600] border-1 border-grey-300/100 hover:shadow-md relative`}
          >
            {" "}
            {initialbut > 1 ? initialbut : "..."}{" "}
          </button>

          <button
            className={`${
              initialbut === pageCount ? "nav-button activess" : "nav-button"
            } p-2 px-3 text-[13px] font-[600] border-1 border-grey-300/100 hover:shadow-md relative`}
          >
            {" "}
            {pageCount}{" "}
            <button
              name="but"
              className="w-full h-full absolute"
              onClick={(e) => handleClick(e, pageCount)}
            />
          </button>
        </>
      )}

      <button className="nav-button relative">
        <BiSolidChevronsRight />
        <button
          name="next"
          className="w-full h-full absolute"
          onClick={handleClick}
        />
      </button>
    </div>
  );
};

export default ButtonPagenate;
