import React, { useEffect } from "react";
import Relatedoccupant from "../Components/Occupants/Relatedoccupant";
import Occupantlist from "../Components/Occupants/Occupantlist";
import { useSelector } from "react-redux";
import Navigate from "../Components/Navigations/Navigate";

const Records = () => {
  const { buttonClicks } = useSelector((state) => state.buttonclick);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    console.log("wendy");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonClicks]);

  return (
    <div className="w-full pr-6 mx-3 h-full flex flex-col min-w-[500px] gap-2">
      <Navigate />

      {buttonClicks === 1 ? (
        <div className="w-full h-full relative">
          <Occupantlist />
        </div>
      ) : buttonClicks === 2 ? (
        <div className="w-full h-full relative">
          <Relatedoccupant />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Records;
